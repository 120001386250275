import React from 'react'
import PropTypes from 'prop-types'
import Tooltip from './Tooltip';

FormGroup.propTypes = {
  label: PropTypes.any,
  htmlFor: PropTypes.string,
  children: PropTypes.any.isRequired,
  labelCols: PropTypes.number.isRequired,
  controlCols: PropTypes.number.isRequired
}

FormGroup.defaultProps = {
  label: undefined,
  htmlFor: undefined
}

export default function FormGroup(props) {
  const { label, children, labelCols, controlCols, htmlFor, ...rest } = props

  return (
    <div className="form-group" {...rest}>
      <label className={`control-label col-xs-${labelCols}`} htmlFor={htmlFor}>
        {label}
      </label>
      <div className={`col-xs-${controlCols}`}>{children}</div>
    </div>
  )
}

FormGroup.WithTooltip = props => {
  const { title, label, ...rest } = props;
  return (
    <FormGroup {...rest} label={<Tooltip title={title}>{label}</Tooltip>} />
  );
};

FormGroup.WithTooltip.propTypes = {
  title: PropTypes.string,
  label: PropTypes.node
};

FormGroup.WithTooltip.defaultProps = {
  title: undefined,
  label: undefined
};
