import { combineReducers } from 'redux'
import breadcrumbPatch from './reducers/breadcrumbPatch'
import pages from './reducers/pages'
import reloadRequired from './reducers/reloadRequired'
import notification from './reducers/notification'
import mappingServiceAreas from './reducers/mappingServiceAreas'

import authentication from './modules/authentication'
import manageOrganizations from './modules/manageOrganizations'
import manageUsers from './modules/manageUsers'
import manageHarvests from './modules/manageHarvests'
import manageDataCollectionProductRequests from './modules/data_collection_outreach/manageDataCollectionProductRequests'
import manageDataCollectionOutreaches from './modules/data_collection_outreach/manageDataCollectionOutreaches'

import { reducer as form } from 'redux-form'
import { reducer as resource } from 'redux-supermodel'
import { reducer as breadcrumb } from 'redux-breadcrumb-trail'
import { reducer as bees } from 'redux-bees'

export default combineReducers({
  bees,
  resource,
  breadcrumb,
  breadcrumbPatch,
  pages,
  reloadRequired,
  notification,
  mappingServiceAreas,
  login: authentication,
  manageOrganizations,
  manageUsers,
  manageHarvests,
  manageDCPRs: manageDataCollectionProductRequests,
  manageDCOs: manageDataCollectionOutreaches,
  form,
})
