import React from 'react'
import PT from 'prop-types'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

/* Fancy React version of the classic <abbr> tag */
export default function Abbr(props) {
  const { id, title, children, ...rest } = props
  const tooltip = <Tooltip id={id}>{title}</Tooltip>

  return (
    <OverlayTrigger placement="bottom" overlay={tooltip} {...rest}>
      <span className="abbr">{children}</span>
    </OverlayTrigger>
  )
}

Abbr.propTypes = {
  id: PT.string.isRequired,
  title: PT.node.isRequired,
  children: PT.node,
}

Abbr.defaultProps = {
  children: null,
}
