import React from 'react'
import { compose, lifecycle } from 'recompose'
import { connect } from 'react-redux'
import { currentUser } from '../lib/opsApi'
import History from '../History'

export const Loading = () => (
  <h1><i className='fa fa-spin fa-refresh' /> Loading...</h1>
)

export const mapProps = state => ({ currentUser: currentUser(state), login: state.login })

export function componentDidMount() {
  const { currentUser } = this.props
  if (!currentUser.busy && !currentUser.initialized) {
    History.replace('/')
  }
}

export function componentDidUpdate(prevProps) {
  if (!this.props.currentUser.initialized && prevProps.currentUser.initialized) {
    const { pathname, search } = this.props.login
    History.replace(pathname + search)
  }
}

export default compose(
  connect(mapProps),
  lifecycle({ componentDidMount, componentDidUpdate })
)(Loading)
