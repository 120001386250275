import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import isArray from 'lodash/isArray'
import { Nav, Navbar, NavDropdown, MenuItem } from 'react-bootstrap'
import { NavLink } from 'redux-breadcrumb-trail'
import { compose, withHandlers } from 'recompose'
import { withRouter } from 'react-router'
import AlertPanel from './Alerts/AlertPanel'
import AlertsMenu from './Alerts/AlertsMenu'
import { withCurrentUser } from './Common'
import Profile from './Profile'
import logo from './logo.png'
import { useNotifications } from '../hooks/useNotifications'

export function AuthenticatedHeader({ hasFeature, currentUser }) {
  const {
    notificationsQueries: {
      allCategoriesQuery: [, { data }],
      markAllNotificationsAsReadMutation: [markAllAsRead]
    }
  } = useNotifications()

  const warnings = data?.warnings || {}
  const messages = data?.messages || {}
  const comments = data?.comments || {}
  const warningsUnread = warnings?.metadata?.unread || 0
  const messagesUnread = messages?.metadata?.unread || 0
  const commentsUnread = comments?.metadata?.unread || 0

  const warningsAndMessagesLabelType = () => {
    if (warningsUnread) return 'label-danger'
    if (messagesUnread) return 'label-warning'
    return 'label-default'
  }

  const commentsLabelType = () => commentsUnread ? 'bg-blue-700' : 'label-default'

  let projectsMenuItems = [
    { label: 'N360', eventKey: '', isHeader: true },
    { label: 'Job Monitor', eventKey: '/n360jobmonitor', isHeader: false },
    { label: 'Custom Attributes', eventKey: '/n360customattributeconfigurations', isHeader: false },
  ];

  let reportsMenuItems = [
    { label: 'Collections', eventKey: '', isHeader: true },
    { label: 'Product Requests', eventKey: '/statisticsreports/productrequests', isHeader: false },
    { label: 'Efforts', eventKey: '/statisticsreports/efforts', isHeader: false },
    { label: 'Medicare Adv Reporting', eventKey: '/medicareadvantagereporting', isHeader: false },
    { label: 'Processing', eventKey: '', isHeader: true },
    { label: 'Project Processing', eventKey: '/projectprocessing', isHeader: false },
  ];

  if (!currentUser.is_global_user) {
    projectsMenuItems.push(
      { label: 'P360', eventKey: '', isHeader: true },
      { label: 'Job Dashboard', eventKey: '/p360/dashboard', isHeader: false },
    );
       
    reportsMenuItems.push(
      { label: 'Database', eventKey: '', isHeader: true },
      { label: 'Internal Queries', eventKey: '/internalqueries', isHeader: false },
    );
  }

  return (
    <>
      <Nav>
        {hasFeature('collections') && (
          <NavDropdown id="collections" title="Collections">
            <MenuItem eventKey="/datacollection/generation_dashboard" style={{width: 190}}>
              Generation Dashboard
            </MenuItem>
            <MenuItem eventKey="/datacollection/efforts">
              Efforts
            </MenuItem>
            <MenuItem divider />
            <MenuItem header>File Import Management</MenuItem>
            <MenuItem eventKey="/datacollection/file_import_management">
              Dashboard
            </MenuItem>
            <MenuItem eventKey="/datacollection/specialty_aliases">
              Specialty Aliases
            </MenuItem>
            <MenuItem divider />
            <MenuItem eventKey="/datacollection/products">
              Product Dashboard
            </MenuItem>
            <MenuItem eventKey="/datacollection/outreaches">
              Outreaches
            </MenuItem>
          </NavDropdown>
        )}

        {hasFeature('processing') && (
          <NavDropdown id="processing" title="Processing">
            <MenuItem header>Data Processing</MenuItem>
            <MenuItem eventKey="/dataprocessing/serviceareas">
              Service Areas
            </MenuItem>
            <MenuItem eventKey="/dataprocessing/networkmappings">
              Network Mappings
            </MenuItem>
            <MenuItem eventKey="/dataprocessing/projectmappings">
              Project Mappings
            </MenuItem>
            <MenuItem header>Medicare Advantage</MenuItem>
            <MenuItem eventKey="/dataprocessing/carrierapprovalrequests">
              Carrier Approval Requests
            </MenuItem>
            <MenuItem eventKey="/dataprocessing/medicareadvantagetemplates">
              Product Templates
            </MenuItem>
            <MenuItem eventKey="/dataprocessing/importproducttemplates">
              Import Product Templates
            </MenuItem>
          </NavDropdown>
        )}

        {hasFeature('harvesting') && (
          <NavDropdown id="harvesting" title="Harvesting">
            <MenuItem eventKey="/harvesting/machinereadablefilemaps">
              Machine Readable Files
            </MenuItem>
            <MenuItem eventKey="/harvesting/scrapingEnvironment">
              Scraping Environment
            </MenuItem>
          </NavDropdown>
        )}

        {hasFeature('projects') && (
          <NavDropdown id="projects" title="Projects">
            {projectsMenuItems.map((item) => (
              <MenuItem header={item.isHeader} eventKey={item.eventKey}>{item.label}</MenuItem>
            ))}
          </NavDropdown>
        )}

        {hasFeature('client_support') && (
          <NavDropdown id="client-support" title="Client Support">
            <MenuItem eventKey="/provider_nexus/requests">
              ProviderNexus API Billing
            </MenuItem>
          </NavDropdown>
        )}

        {hasFeature(['pdm', 'pdm_editor']) && (
          <NavDropdown id="contracts" title="Contracts">
            <MenuItem eventKey="/pdm/contracts/search">
              Contract Search
            </MenuItem>
            {hasFeature('pdm_editor') && [
              <MenuItem key="0" eventKey="/pdm/contracts/new">
                Add New Contract
              </MenuItem>,
              <MenuItem key="1" divider />,
              <MenuItem key="2" eventKey="/pdm/auditreports">
                Audit Reports
              </MenuItem>,
              <MenuItem key="3" eventKey="/pdm/affiliates">
                Affiliates
              </MenuItem>,
              <MenuItem key="4" eventKey="/pdm/feeschedules">
                Fee Schedules
              </MenuItem>,
              <MenuItem key="5" eventKey="/pdm/networks">
                Networks
              </MenuItem>,
              <MenuItem key="6" eventKey="/pdm/exclusions">
                Exclusions
              </MenuItem>,
            ]}
          </NavDropdown>
        )}

        <NavDropdown id="statisticsReports" title="Reports">
          {reportsMenuItems.map((item) => (
            <MenuItem header={item.isHeader} eventKey={item.eventKey}>{item.label}</MenuItem>
          ))}
        </NavDropdown>
      </Nav>
      <Nav pullRight>
        <NavDropdown id="cog" title={<i className="fa fa-lg fa-cog" />}>
          <MenuItem eventKey="/organizations">Organizations</MenuItem>
          <MenuItem eventKey="/sources">Sources</MenuItem>
          <MenuItem eventKey="/employers">Employers</MenuItem>
          {hasFeature('users') && [
            <MenuItem key="0" divider />,
            <MenuItem key="1" eventKey="/users">
              Users
            </MenuItem>,
          ]}
        </NavDropdown>

        <AlertsMenu
          dropdownId="alerts"
          labelClass={warningsAndMessagesLabelType()}
          icon="fa-bell"
          totalUnread={(warnings?.metadata?.unread || 0) + (messages?.metadata?.unread || 0)}
        >
          <AlertPanel
            type="warnings"
            bsClass="danger"
            data={warnings}
            onReadAll={() => markAllAsRead({ variables: { alertCategory: "WARNINGS" } })}
          />
          <AlertPanel
            type="messages"
            bsClass="warning"
            data={messages}
            onReadAll={() => markAllAsRead({ variables: { alertCategory: "MESSAGES" } })}
          />
        </AlertsMenu>

        {currentUser.is_carrier_relationship_manager &&
          <AlertsMenu
            dropdownId="comments"
            labelClass={commentsLabelType()}
            icon="fa-comment"
            totalUnread={comments?.metadata?.unread || 0}
            linkTo={{ path: '/alerts/comments', text: 'See all Comments' }}
          >
            <AlertPanel
              type="comments"
              bsClass="primary"
              data={comments}
              onReadAll={() => markAllAsRead({ variables: { alertCategory: "COMMENTS" } })}
            />
          </AlertsMenu>
        }

        <NavDropdown
          id="profile"
          title={<Profile />}
          noCaret
          className="navbar-profile"
        >
          <MenuItem eventKey="/profile">Profile</MenuItem>
          <MenuItem eventKey="/bookmarks">Bookmarks</MenuItem>
          <MenuItem divider />
          <MenuItem eventKey="/logout">Logout</MenuItem>
        </NavDropdown>
      </Nav>
    </>
  )
}

AuthenticatedHeader.propTypes = {
  hasFeature: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired
}

export const hasFeature = (props) => (features) => {
  if (!isArray(features)) {
    features = [features]
  }

  return (
    props.currentUser &&
    features.some((feature) => props.currentUser.features.includes(feature))
  )
}

const WrappedAuthenticatedHeader = compose(
  withRouter,
  withCurrentUser,
  withHandlers({ hasFeature })
)(AuthenticatedHeader)

export default function Header({ router, isLoggedIn }) {
  const handleSelect = useCallback(
    (pathname) => {
      router.push({ pathname, state: { breadcrumb: 'reset' } })
    },
    [router]
  )

  return (
    <Navbar fluid onSelect={handleSelect}>
      <Navbar.Header>
        <Navbar.Brand>
          <NavLink to="/">
            <img alt="OpsPrime" src={logo} />
          </NavLink>
        </Navbar.Brand>

        {isLoggedIn && <Navbar.Toggle />}
      </Navbar.Header>

      {isLoggedIn && (
        <Navbar.Collapse>
          <WrappedAuthenticatedHeader />
        </Navbar.Collapse>
      )}
    </Navbar>
  )
}

Header.propTypes = {
  router: PropTypes.object.isRequired,
  isLoggedIn: PropTypes.bool,
}

Header.defaultProps = {
  isLoggedIn: false,
}
