import { compose, withProps } from 'recompose'
import { Radio } from 'react-bootstrap'
import { withFeedback } from './FieldFeedback'

Radio.Field = compose(
  withProps(props => {
    // Copy input to props, but don't allow `value` to be over-written.
    const value = props.value
    const input = props.input
    return {
      ...input,
      // Create proprty `checked`.
      checked: value === input.value,
      value
    };
  }),
  withFeedback
)(Radio)

export default Radio
