import React from 'react'
import { bool, node, oneOfType, string } from 'prop-types'

export default function SubmitButton (props) {
  const {submitting, disabled, value, icon, children, ...rest} = props
  return (
    <button type='submit' className='btn btn-primary' disabled={submitting || disabled} {...rest}>
      <i className={`fa fa-${icon}`} /> {value || children} {submitting && <i className='fa fa-cog fa-spin' />}
    </button>
  )
}

SubmitButton.propTypes = {
  submitting: bool,
  disabled: bool,
  value: oneOfType([node, string]),
  icon: string,
}

SubmitButton.defaultProps = {
  value: 'Save',
  icon: 'save'
}
