import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { SubmissionError } from 'redux-form'
import { FormGroup, FormControl, InputGroup } from 'react-bootstrap'
import capitalize from 'lodash/capitalize'
import Tooltip from '../Tooltip'

FieldFeedback.propTypes = {
  meta: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  hideValidation: PropTypes.bool,
  leftInputGroupText: PropTypes.node,
  rightInputGroupText: PropTypes.node,
  onRefresh: PropTypes.func,
  style: PropTypes.object,
}

FieldFeedback.defaultProps = {
  hideValidation: false,
  leftInputGroupText: null,
  rightInputGroupText: null,
  onRefresh: null,
  style: null,
}

export default function FieldFeedback(props) {
  const {
    children,
    meta: { touched, error, warning } = {},
    hideValidation,
    leftInputGroupText,
    rightInputGroupText,
    onRefresh,
    style,
  } = props

  const validationState = useMemo(() => {
    if (hideValidation) return null
    if (touched && error) return 'error'
    if (touched && warning) return 'warning'
    return null
  }, [hideValidation, touched, error, warning])

  const errorFeedback = useMemo(() => {
    function handleRefresh() {
      if (onRefresh) {
        return onRefresh()
      }
      window.location.reload()
    }

    if (!touched || !error || !(error instanceof SubmissionError)) return null

    return (
      <span
        className="form-control-feedback"
        style={{ pointerEvents: 'initial', right: '8em' }}
      >
        <button type="button" className="btn btn-link" onClick={handleRefresh}>
          <i className="fa fa-refresh" /> Refresh
        </button>
      </span>
    )
  }, [onRefresh, error, touched])

  const tooltip = useMemo(() => {
    if (!touched || hideValidation) return undefined

    if (error instanceof SubmissionError)
      return 'An error prevented the field from loading properly.'

    return capitalize(error || warning) || undefined
  }, [error, warning, touched, hideValidation])

  return (
    <Tooltip id="fieldTooltip" title={tooltip}>
      {(() => {
        if (rightInputGroupText) {
          return (
            <FormGroup
              validationState={validationState}
              bsClass="has-right-input-group"
              style={style}
            >
              <InputGroup>
                {children}
                <InputGroup.Addon>{rightInputGroupText}</InputGroup.Addon>
              </InputGroup>
              <FormControl.Feedback />
              {errorFeedback}
            </FormGroup>
          )
        }

        if (leftInputGroupText) {
          return (
            <FormGroup
              validationState={validationState}
              bsClass=""
              style={style}
            >
              <InputGroup>
                <InputGroup.Addon>{leftInputGroupText}</InputGroup.Addon>
                {children}
              </InputGroup>
              <FormControl.Feedback />
              {errorFeedback}
            </FormGroup>
          )
        }

        return (
          <FormGroup validationState={validationState} bsClass="" style={style}>
            {children}
            <FormControl.Feedback />
            {errorFeedback}
          </FormGroup>
        )
      })()}
    </Tooltip>
  )
}

export const withFeedback = (Component) => ({
  /* eslint-disable react/prop-types */
  meta,
  hideValidation,
  leftInputGroupText,
  rightInputGroupText,
  /* eslint-enable react/prop-types */
  ...rest
}) => (
  <FieldFeedback
    meta={meta}
    hideValidation={hideValidation}
    leftInputGroupText={leftInputGroupText}
    rightInputGroupText={rightInputGroupText}
  >
    <Component {...rest} />
  </FieldFeedback>
)
