import asyncComponent from '../AsyncComponent'

export const ProductTemplatesPage = asyncComponent(() =>
  import(/* webpackChunkName: "MedicareAdvantage" */ './ProductTemplatesPage')
)

export const CarrierApprovalPage = asyncComponent(() =>
  import(/* webpackChunkName: "MedicareAdvantage" */ './CarrierApprovalPage')
)

export const CarrierApprovalDetailPage = asyncComponent(() =>
  import(/* webpackChunkName: "MedicareAdvantage" */ './CarrierApprovalDetailPage')
)

export const ImportProductTemplatesPage = asyncComponent(() =>
  import(/* webpackChunkName: "MedicareAdvantage" */ './ImportProductTemplatesPage')
)
