export const SOURCE_SELECTION_CHANGE = 'ops/manageHarvests/SOURCE_SELECTION_CHANGE'

export const fileTypes = [
  {label: 'All File Types',
    value: null},
  {label: 'Provider',
    value: 'provider'},
  {label: 'Plan',
    value: 'plan'},
]

export const mapTypes = [
  {label: 'All Map Types',
    value: null},
  {label: 'Table per File',
    value: 'table_per_file'},
  {label: 'Single Table',
    value: 'single_table'},
  {label: 'Custom',
    value: 'custom'},
]

export const existingData = [
  {label: 'Append',
    value: 'true'},
  {label: 'Archive',
    value: 'false'},
]

const initialState = { selectedSource: null }

export default function reducer (state = initialState, action) {
  switch (action.type) {
    case SOURCE_SELECTION_CHANGE:
      return { selectedSource: action.payload }
    default:
      return state
  }
}

export function changeSourceSelection (payload) {
  return {
    type: SOURCE_SELECTION_CHANGE,
    payload,
  }
}
