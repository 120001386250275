export default class Config {
  static ioHost () {
    return process.env.REACT_APP_IO_HOST
  }

  static securityServiceHost () {
    return process.env.REACT_APP_SECURITY_SERVICE_HOST
  }

  static opsServiceHost () {
    return process.env.REACT_APP_OPS_SERVICE_HOST
  }

  static isProduction () {
    return process.env.REACT_APP_NODE_ENV === "production"
  }
}
