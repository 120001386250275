import _ from 'lodash'
import React from 'react'
import { any, object, func, string, bool, oneOfType } from 'prop-types'
import { compose, flattenProp, withProps } from 'recompose'
import { connect } from 'react-redux'
import { withFeedback } from '../Fields/FieldFeedback'
import SelectDropDown from './SelectDropDown'

export function mapResourceOptions (options, label, valueKey) {
  return _(options).filter(option => option).map((option, id) => {
    const optionValue = option[valueKey] || id
    const optionLabel = (typeof label === 'function' && label(option)) || option[label]
    return {
      key: optionValue,
      value: optionValue,
      label: optionLabel
    }
  }).sortBy(
    ({label}) => label && label.toLowerCase()
  ).value()
}

export class ResourceDropDown extends React.Component {
  static propTypes = {
    resource: object.isRequired,
    optionsRenderer: func,
    label: oneOfType([func, string]).isRequired,
    valueKey: string.isRequired,
    value: any,
    onChange: func.isRequired,
    showPlaceholder: bool,
  }

  static defaultProps = {
    placeholder: 'Select...',
    label: 'name',
    valueKey: 'id',
  }

  optionsRendererDefault = options => {
    const { label, valueKey } = this.props
    return mapResourceOptions(options, label, valueKey)
  }

  renderOptions = records => {
    const { showPlaceholder, optionsRenderer, placeholder } = this.props
    const options = (optionsRenderer || this.optionsRendererDefault)(records)
    if (!options || !showPlaceholder) {
      return options
    }

    return [
      {
        key: 0,
        value: null,
        label: placeholder
      },
      ...options,
    ]
  }

  render () {
    // eslint-disable-next-line no-unused-vars
    const { resource: { ready, error, payload }, valueKey, onRefresh, ...rest } = this.props
    if (error) {
      function handleRefresh() {
        if (onRefresh) {
          onRefresh();
        } else {
          window.location.reload();
        }
      }

      return (
        <React.Fragment>
          <SelectDropDown {...rest} disabled placeholder="" />
          <span
            className="form-control-feedback"
            style={{ pointerEvents: 'initial', right: 90 }}
          >
            <button
              type="button"
              className="btn btn-link"
              onClick={handleRefresh}
            >
              <i className="fa fa-refresh" /> Refresh
            </button>
          </span>
        </React.Fragment>
      )
    }

    if (!ready || !payload || !payload.data) {
      return <SelectDropDown {...rest} disabled isLoading />
    }

    return (
      <SelectDropDown
        options={this.renderOptions(payload.data)}
        searchable
        {...rest}
      />
    )
  }
}

export const createProps = props => {
  if (props.resource && props.resource.error) {
    return {
      meta: {
        ...props.meta,
        touched: true,
        error: props.resource.error
      }
    }
  }
}

export default function withResource (resource) {
  const mapStateToProps = state => ({
    resource: resource(state),
    valueKey: resource.idAttribute,
  })

  const Connected = connect(mapStateToProps)(ResourceDropDown)
  Connected.Field = compose(
    connect(mapStateToProps),
    flattenProp('input'),
    withProps(createProps),
    withFeedback
  )(ResourceDropDown)

  return Connected
}
