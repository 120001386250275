import keyBy from 'lodash/keyBy'

export const supplierCoverageTypesOptions = [
  { value: 'medical', label: 'Medical' },
  { value: 'dental', label: 'Dental' },
  { value: 'vision', label: 'Vision' },
  { value: 'mental_health', label: 'Mental Health' }
]

export const productTypes = [
  { label: 'ACO - Accountable Care Organizations', value: 'aco' },
  {
    label:
      'DHMO/DMO - Dental Health Maintenance Organization/Dental Maintenance Organization',
    value: 'dhmo_dmo'
  },
  { label: 'DPPO - Dental Preferred Provider Organization', value: 'dppo' },
  { label: 'PPO - Preferred Provider Organization', value: 'ppo' },
  { label: 'POS - Point-Of-Service (Plan)', value: 'pos' },
  { label: 'HMO - Health Maintenance Organization', value: 'hmo' },
  { label: 'PFFS - (Medicare) Private Fee-for-Service', value: 'pffs' },
  { label: 'INDEMNITY - Fee-For-Service (Plan)', value: 'indemnity' },
  { label: 'VISION - Vision Plan', value: 'vision' },
  {
    label: 'HMO/POS - Health Maintenance Organization/Point-Of-Service (Plan)',
    value: 'hmo_pos'
  },
  { label: 'EPO - Exclusive Provider Organization ', value: 'epo' },
  {
    label:
      'EPO/PPO - Exclusive Provider Organization/Preferred Provider Organization',
    value: 'epo_ppo'
  },
  { label: 'Rx - Pharmacy', value: 'rx' },
  { label: 'CDHP - Consumer Directed Health Plan', value: 'cdhp' },
  { label: 'Unknown', value: 'unknown' }
]

const contactTypesOptions = [
  { value: 'data', label: 'Data' },
  { value: 'business', label: 'Business' },
  { value: 'technical', label: 'IT/Technical' }
]
export const contactTypesIndexedOptions = keyBy(contactTypesOptions, 'value')

export const enrollmentTypesOptions = [
  {
    value: 'pcp_or_group_enrollment_not_required',
    label: 'PCP or group enrollment not required'
  },
  { value: 'pcp_enrollment', label: 'PCP enrollment' },
  { value: 'pcp_in_a_medical_group', label: 'PCP in a medical group' }
]

const initialState = {
  supplierCoverageTypes: supplierCoverageTypesOptions,
  contactTypes: contactTypesOptions,
  contactTypesIndexed: contactTypesIndexedOptions,
  enrollmentTypes: enrollmentTypesOptions
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state
  }
}
