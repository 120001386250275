import React, { Component } from 'react'
import ErrorPanel from './ErrorPanel'

export default class ErrorBoundary extends Component {
  state = { error: null, info: null }

  componentDidCatch (error, info) {
    this.setState({ error, info })

    if (global.Raygun) {
      global.Raygun.send(error, info)
    }
  }

  clearError = () => this.setState({ error: null, info: null })

  render () {
    if (this.state.error) {
      return (
        <ErrorPanel {...this.state} onClose={this.clearError} />
      )
    }

    return this.props.children
  }
}
