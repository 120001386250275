import _ from 'lodash'
import React, { Component } from 'react'
import { findDOMNode } from 'react-dom'
import { func, object, string } from 'prop-types'
import { connect } from 'react-redux'
import toastr from 'toastr'

import { bookmarks } from '../../lib/opsApi'

// Create a bookmark for the current page. If a text prop is not set,
// the component will try to read the current breadcrumb's text.
export class Bookmark extends Component {
  static propTypes = {
    breadcrumb: object,
    createBookmark: func.isRequired,
    text: string,
  }

  /* eslint-disable react/no-find-dom-node */
  getBreadcrumbText = () => this.breadcrumbComponent && findDOMNode(this.breadcrumbComponent).innerText
  /* eslint-enable react/no-find-dom-node */

  handleClick = async () => {
    try {
      const { createBookmark, text } = this.props
      const { pathname, search, hash } = window.location

      await createBookmark({
        text: text || this.getBreadcrumbText(),
        url: pathname + search + hash,
      })

      toastr.success('Bookmark saved.', 'Success')
    } catch (err) {
      toastr.error(err.message, 'Failure')
    }
  }

  refBreadcrumb = ref => this.breadcrumbComponent = ref

  render () {
    const { text, breadcrumb: { component } = {} } = this.props

    return (
      <button type="button" className="btn btn-sm btn-default" onClick={this.handleClick}>
        <i className='fa fa-star'/> Bookmark

        {!text && component
          && <div style={{display: 'none'}}>
            {React.createElement(component, { ref: this.refBreadcrumb })}
          </div>
        }
      </button>
    )
  }
}

export const mapProps = state => ({ breadcrumb: _.last(state.breadcrumb.breadcrumbs) })

export default connect(mapProps, { createBookmark: bookmarks.create })(Bookmark)
