const PREFIX = 'ops/authentication'
export const SET_NEXT_LOCATION = `${PREFIX}/SET_NEXT_LOCATION`

export const setNextLocation = nextLocation => ({ type: SET_NEXT_LOCATION, nextLocation })

export default function reducer (state={}, action) {
  switch (action.type) {
    case SET_NEXT_LOCATION: {
      const { nextLocation } = action
      return Object.assign({}, state, nextLocation)
    }
    default:
      return state
  }
}
