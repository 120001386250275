const TAX_ID_KEY = "TAX_ID"
const PROJECT_MAPPING_FILTERS_KEY = "PROJECT_MAPPING_FILTERS"

export function setTaxId (taxId) {
  if (typeof sessionStorage !== 'undefined') {
    sessionStorage.setItem(TAX_ID_KEY, taxId)
  }
}

export function getTaxId () {
  if (typeof sessionStorage !== 'undefined') {
    return sessionStorage.getItem(TAX_ID_KEY)
  }
}

export function deleteTaxId () {
  if (typeof sessionStorage !== 'undefined') {
    return sessionStorage.removeItem(TAX_ID_KEY)
  }
}

export function setProjectMappingFilter (mapping_id, filter) {
  if (typeof sessionStorage !== 'undefined') {
    let mapping_key = "mapping_" + mapping_id
    let currentFilters = getProjectMappingFilters()
    currentFilters[mapping_key] = {...currentFilters[mapping_key] || {}, ...filter}
    sessionStorage.setItem(PROJECT_MAPPING_FILTERS_KEY, JSON.stringify(currentFilters))
  }
}

export function getProjectMappingFilters (mapping_id) {
  if (typeof sessionStorage !== 'undefined') {
    let filters = sessionStorage.getItem(PROJECT_MAPPING_FILTERS_KEY)
    if(filters != null) {
      filters = JSON.parse(filters)
      if (mapping_id != null) {
        filters = filters["mapping_" + mapping_id];
      }
      return filters || {};
    }
  }
  return {};
}

export const setFiltersForKey = (key, filters) => {
  if (typeof sessionStorage !== 'undefined') {
    sessionStorage.setItem(key, JSON.stringify(filters))
  }
}

export const getFiltersForKey = key => {
  if (typeof sessionStorage !== 'undefined') {
    let filters = sessionStorage.getItem(key)
    if(filters != null) {
      filters = JSON.parse(filters)
    }
    return filters;
  }
}
