import React, { useEffect } from 'react';
import $ from 'jquery';
import 'daterangepicker/daterangepicker.css';
import 'daterangepicker';

const DateRangePicker = ({ options, onChange }) => {
  useEffect(() => {
    $('#daterangepicker').daterangepicker(options, function(start, end, label) {
      onChange(start, end, label);
    });
  }, [onChange]);

  return (
    <input type="text" id="daterangepicker" className='block w-full p-3 border border-solid border-gray-400 rounded-md'/>
  );
};

export default DateRangePicker;

