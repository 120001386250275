import React from 'react'
import { bool, func, string } from 'prop-types'
import { withHandlers } from 'recompose'
import Tooltip from './Tooltip'

export function PrettyCheckBox(props) {
  const { name, onClick, ...rest } = props
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a href='#' onClick={onClick}>
      <Tooltip id={name} {...rest} />
    </a>
  )
}

PrettyCheckBox.propTypes = {
  value: bool,
  checked: bool, // alias for value
  name: string.isRequired,
  onClick: func.isRequired,
}

const onClick = props => e => {
  e.preventDefault()
  const val = props.value || props.checked
  props.onChange(!val)
}

export default withHandlers({ onClick })(PrettyCheckBox)
