import React, { createContext, useContext, useState } from 'react'
import PropTypes from 'prop-types'

const ModalContext = createContext()

ModalProvider.propTypes = {
  children: PropTypes.func.isRequired
}

export default function ModalProvider({ children }) {
  const [modal, setModal] = useState(null)

  return (
    <ModalContext.Provider value={setModal}>
      {children(modal)}
    </ModalContext.Provider>
  )
}

export function useModal() {
  return useContext(ModalContext)
}
