import React from 'react'
import { bool, func, node } from 'prop-types'
import { Modal } from 'react-bootstrap'

ConfirmDialog.propTypes = {
  /** The title, positioned in the confirmation's header */
  title: node.isRequired,
  /** The message body of the confirmation */
  body: node.isRequired,
  /** Shows the confirmation when true */
  show: bool,
  /** If true, shows a spinner because an operation is in progress */
  inProgress: bool,
  /** Callback when the confirm button is clicked */
  onConfirm: func,
  /** Callback when the dialog is closed */
  onClose: func
}

ConfirmDialog.defaultProps = {
  show: false,
  inProgress: false,
  onConfirm: null,
  onClose: null
}

export default function ConfirmDialog(props) {
  const { title, body, show, inProgress, onConfirm, onClose } = props

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{body}</p>
      </Modal.Body>
      <Modal.Footer>
        <button id="close" className="btn btn-default" onClick={onClose}>
          No
        </button>
        <button
          id="confirm"
          className="btn btn-primary"
          autoFocus
          onClick={onConfirm}
          disabled={inProgress}
        >
          Yes <i className={inProgress ? 'fa fa-cog fa-spin' : ''} />
        </button>
      </Modal.Footer>
    </Modal>
  )
}
