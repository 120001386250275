import { useState, useEffect, useMemo, useCallback } from 'react'
import _ from 'lodash'
import { client } from '../lib/opsApi'

export default function useApiFetch(url, options = {}) {
  const [previousOptions, setOptions] = useState(null)
  const [loading, setLoading] = useState(true)
  const [results, setResults] = useState()
  const [error, setError] = useState(null)

  // Converts the URL and params to a string for use by the cache.
  const resolver = useCallback(
    (url, params) => `{"${url}": ${JSON.stringify(params)}}`,
    []
  )

  // The fetch function is what actually retrieves data from the server.
  // We call useMemo because it only needs to be created once, otherwise
  // the cache will always be empty. We _.memoize it so that repeated calls
  // to the same url/params will return the results from the last call.
  const fetch = useMemo(
    () =>
      _.memoize((url, params) => client.request({ url, ...params }), resolver),
    [resolver]
  )

  // Remove the currently fetched page from the cache so that when it
  // refetches, it will hit the server again.
  const refetch = useCallback(
    () => {
      fetch.cache.delete(resolver(url, options))
      setOptions(null)
    },
    [url, options, fetch, resolver]
  )

  // Clear the whole cache so that every request will hit the server again.
  const invalidate = useCallback(
    () => {
      fetch.cache.clear()
      setOptions(null)
    },
    [fetch]
  )

  // Replaces the fetched data with the data in the parameter
  // and resets any other cached data, since this is likely to
  // be called in response to a PUT or PATCH request.
  const reset = useCallback(
    data => {
      fetch.cache.clear()
      if (data) {
        fetch.cache.set(resolver(url, options), data)
      }
      setResults(data)
    },
    [fetch, url, options, resolver]
  )

  useEffect(
    () => {
      if (_.isEqual(options, previousOptions)) return

      setOptions(options)
      if (url) {
        fetchData()
      }

      // TODO: convert this to Suspense later.
      async function fetchData() {
        try {
          setLoading(true)
          const res = await fetch(url, options)
          setResults(res.data)
          setError(null)
        } catch (err) {
          setError(err)
        }

        setLoading(false)
      }
    },
    [url, options, previousOptions, fetch]
  )

  useEffect(
    () => {
      if (options.invalidate) {
        invalidate()
      }
    },
    [options.invalidate, invalidate]
  )

  useEffect(
    () => {
      setOptions(null)
    },
    [url, setOptions]
  )

  return {
    loading,
    results,
    error,
    refetch,
    invalidate,
    reset
  }
}

export { client }
