import asyncComponent from '../AsyncComponent'

export const Login = asyncComponent(() =>
  import(/* webpackChunkName: "Auth" */ './Login')
)

export const Logout = asyncComponent(() =>
  import(/* webpackChunkName: "Auth" */ './Logout')
)

export const ForgotPassword = asyncComponent(() =>
  import(/* webpackChunkName: "Auth" */ './ForgotPassword')
)

export const Confirmations = asyncComponent(() =>
  import(/* webpackChunkName: "Auth" */ './Confirmations')
)

export const ResetPassword = asyncComponent(() =>
  import(/* webpackChunkName: "Auth" */ './ResetPassword')
)
