import React from 'react'
import { func, object, string } from 'prop-types'
import { withHandlers } from 'recompose'

export function SortableTableHeader(props) {
  const { column, sort, sortOrder, handleSort } = props
  const sortClass = column.type === 'string' ? `fa fa-sort-alpha-${sortOrder}` : `fa fa-sort-${sortOrder}`

  return (
    <th key={column.value} className='text-center sortable' onClick={handleSort}>
      {column.label} {sort === column.value && <i className={sortClass} />}
    </th>
  )
}

SortableTableHeader.propTypes = {
  column: object.isRequired,
  onSort: func.isRequired,
  sort: string.isRequired,
  sortOrder: string.isRequired,
}

export const handleSort = props => e => {
  e.preventDefault()
  props.onSort(props.column)
}

export default withHandlers({ handleSort })(SortableTableHeader)
