import _ from 'lodash'
import React, { Component } from 'react'
import { shape } from 'prop-types'
import { Cell } from 'fixed-data-table-2'
import Truncate from 'react-truncate'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { dataContextShape } from '../DataContext'

export default class TextCell extends Component {
  static contextTypes = { data: shape(dataContextShape) }

  static defaultProps = { lines: 2 }

  handleClick = e => e.preventDefault()

  renderEllipsis = title => (
    <OverlayTrigger placement="top" overlay={<Tooltip id="text">{title}</Tooltip>}>
      {/* eslint-disable jsx-a11y/anchor-is-valid */}
      <a href="#" onClick={this.handleClick}>...</a>
      {/* eslint-enable jsx-a11y/anchor-is-valid */}
    </OverlayTrigger>
  )

  render () {
    const { rowIndex, column, lines, truncate, ...rest } = this.props
    const { getObjectAt, version } = this.context.data
    const data = getObjectAt(rowIndex)

    if (!data) {
      return <Cell />
    }

    const val = _.isFunction(column)
      ? column(data)
      : _.get(data, column)

    const content = truncate
      ? <div><Truncate lines={lines} ellipsis={this.renderEllipsis(val)}>{val}</Truncate></div>
      : val

    return (
      <Cell version={version} {...rest}>{content}</Cell>
    )
  }
}
