import _ from 'lodash'

export default function collectionTransformer (payload, previous, isFulfilled, meta) {
  const { action, inputData } = meta

  switch (action) {
    case 'create': {
      if (!isFulfilled) {
        return previous
      }

      const data = (previous && previous.data && previous.data.slice(0)) || []
      data.push(payload)

      return { ...previous,
        data }
    }
    case 'update': {
      if (!isFulfilled) {
        return previous
      }
      if (!inputData) {
        return previous
      }

      const data = (previous && previous.data && previous.data.slice(0)) || []
      const index = _.findIndex(data, x => x.id === inputData.id)
      if (index >= 0) {
        data[index] = _.merge(data[index], payload.data)
      }

      return { ...payload,
        data }
    }
    case 'destroy': {
      if (!inputData) {
        return previous
      }

      let data
      if (isFulfilled) {
        data = _.filter(previous && previous.data, x => x.id !== inputData.id)
        return { ...payload,
          data }
      }

      data = (previous && previous.data && previous.data.slice(0)) || []
      const index = _.findIndex(data, x => x.id === inputData.id)
      if (index >= 0) {
        data[index] = { ...data[index],
          pendingDelete: true }
      }

      return { ...previous, data }
    }
    default:
      return isFulfilled ? payload : previous
  }
}
