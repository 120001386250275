import React from 'react'
import { compose, flattenProp } from 'recompose'
import { FormControl } from 'react-bootstrap'
import { withFeedback } from './FieldFeedback'

FormControl.Field = compose(
  flattenProp('input'),
  withFeedback
)(FormControl)

FormControl.Static.Field = ({input, ...rest}) => (
  <FormControl.Static {...rest} children={input.value} />
)

export default FormControl
