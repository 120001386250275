import React from 'react'
import { string, object, oneOf } from 'prop-types'
import Tooltip from '../Tooltip'

export default function ValidatedField (props) {
  const { name, placement, children, field } = props
  const { touched, error } = field.meta

  return (
    <Tooltip
      id={`${name}_tooltip`}
      title={touched && error}
      hide={!(touched && error)}
      placement={placement}>
      <div className={(touched && error && 'has-error') || 'valid'}>
        {children}
      </div>
    </Tooltip>
  )
}

ValidatedField.propTypes = {
  name: string.isRequired,
  field: object.isRequired,
  placement: oneOf(['top', 'bottom', 'left', 'right']),
}

ValidatedField.defaultProps = {
  placement: 'right',
}
