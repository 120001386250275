import React from 'react'

const divStyle = { marginBottom: 0 }
export default function DeliveryDetailLine ({ label, field }) {
  return (
    <div className='form-group' style={divStyle}>
      <label className='col-xs-3 control-label'>{label}</label>
      <div className='col-xs-9 form-control-static'>{(field && field.value) || field}</div>
    </div>
  )
}
