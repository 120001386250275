import React from 'react'
import PropTypes from 'prop-types'
import md5 from 'md5'

Gravatar.propTypes = {
  /** The email address to locate the gravatar profile for */
  email: PropTypes.string,
  /** Size of the gravatar */
  size: PropTypes.number
}

Gravatar.defaultProps = {
  email: '',
  size: 50
}

/** Display an email address as a gravatar */
export default function Gravatar({ email, size }) {
  const gravatar = `https://www.gravatar.com/avatar/${md5(
    email
  )}?d=mm&size=${size}`
  return (
    <img
      src={gravatar}
      alt=""
      className="img-rounded"
      style={{ width: size, height: size }}
    />
  )
}
