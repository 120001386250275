import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Alert } from 'react-bootstrap'

ErrorSummary.propTypes = {
  showLabels: PropTypes.bool,
  errors: PropTypes.object,
  onDismiss: PropTypes.func,
  children: PropTypes.node
}

ErrorSummary.defaultProps = {
  showLabels: false,
  errors: null,
  onDismiss: null,
  children: null
}

export default function ErrorSummary({
  showLabels,
  errors,
  onDismiss,
  children
}) {
  if (_.isEmpty(errors)) return null

  const items = Object.keys(errors).map(key => {
    const error = Array.isArray(errors[key])
      ? errors[key].join(' ')
      : errors[key]

    const text = showLabels
      ? `${_.titleize(key.replace('_', ' '))} ${error.charAt(0).toLowerCase() +
          error.slice(1)}`
      : error

    return <li key={key}>{text}</li>
  })

  return (
    <Alert bsStyle="danger" onDismiss={onDismiss}>
      {children || <h5>Errors</h5>}
      <ul>{items}</ul>
    </Alert>
  )
}
