import { createContext, useContext, useState, useCallback } from 'react'
import { useLazyQuery, useMutation } from '@apollo/react-hooks'
import toastr from 'toastr'
import gql from 'graphql-tag'

import createAlertUrl from '../components/Alerts/createAlertUrl'

export const NotificationsContext = createContext()

export function useNotifications() {
  return useContext(NotificationsContext)
}

export function useNotificationsDetails(router) {
  const [notificationsFilter, setNotificationsFilter] = useState('ALL')
  const [page, setPage] = useState(1)

  const paginatedQuery = useLazyQuery(QUERY, {
    fetchPolicy: "cache-and-network",
    variables: { page, alertCategory: notificationsFilter === 'ALL' ? null : notificationsFilter }
  })
  const [, { refetch: paginatedQueryRefetch, called: paginatedQueryCalled }] = paginatedQuery

  const allCategoriesQuery = useLazyQuery(ALL_CATEGORIES_QUERY, {
    fetchPolicy: "cache-and-network"
  })
  const [, { refetch: allCategoriesQueryRefetch, called: categorizedQueryCalled }] = allCategoriesQuery

  /* eslint-disable react-hooks/exhaustive-deps */
  const refetchNotifications = useCallback(() => {
    if (paginatedQueryCalled) paginatedQueryRefetch()
    if (categorizedQueryCalled) allCategoriesQueryRefetch()
  }, [paginatedQueryCalled, categorizedQueryCalled])
  /* eslint-enable react-hooks/exhaustive-deps */

  const deleteNotificationMutation = useMutation(DESTROY, {
      onCompleted() {
        toastr.success('Notification deleted.')
        refetchNotifications()
      },
      onError(err) {
        toastr.error(err.message)
      }
    }
  )

  const markNotificationAsReadMutation = useMutation(MARK_AS_READ, {
    onCompleted() {
      refetchNotifications()
    },
    onError(err) {
      toastr.error(err.message)
    }
  })
  const [markNotificationAsRead] = markNotificationAsReadMutation
  const markAllNotificationsAsReadMutation = useMutation(MARK_ALL_AS_READ, {
      onCompleted() {
        toastr.success('All notifications marked as read.')
        refetchNotifications()
      },
      onError(err) {
        toastr.error(err.message)
      }
    }
  )

  const viewNotificationTarget = async (notification) => {
    if (!notification.hasViewed) await markNotificationAsRead({ variables: { id: notification.id } })

    const url = createAlertUrl(notification)

    if (typeof url === 'object') return router.push({ state: { breadcrumb: 'reset' }, ...url })

    router.push({ pathname: url, state: { breadcrumb: 'reset' } })
  }

  return {
    notificationsQueries: {
      paginatedQuery,
      allCategoriesQuery,
      deleteNotificationMutation,
      markNotificationAsReadMutation,
      markAllNotificationsAsReadMutation
    },
    notificationsFilter,
    setNotificationsFilter,
    refetchNotifications,
    viewNotificationTarget,
    page,
    setPage
  }
}

const NOTIFICATIONS_FRAGMENT = gql`
  fragment NotificationList on NotificationCollection {
    collection {
      id
      createdAt
      hasViewed
      alert {
        displayName
        gravatarUrl
        data
        message
        systemGenerated
        type
        category
      }
    }
    metadata {
      totalPages
      totalCount
      currentPage
      limitValue
      unread
    }
  }
`

const QUERY = gql`
  ${NOTIFICATIONS_FRAGMENT}

  query FilteredNotifications($page: Int, $alertCategory: AlertCategoryFilterEnum) {
    notifications: notificationsByCategory(page: $page, alertCategory: $alertCategory) {
      ...NotificationList
    }
  }
`

const ALL_CATEGORIES_QUERY = gql`
  ${NOTIFICATIONS_FRAGMENT}

  query AllCategoriesNotifications {
    warnings: notificationsByCategory(alertCategory: WARNINGS, limit: 4) {
      ...NotificationList
    }
    messages: notificationsByCategory(alertCategory: MESSAGES, limit: 4) {
      ...NotificationList
    }
    comments: notificationsByCategory(alertCategory: COMMENTS, limit: 4) {
      ...NotificationList
    }
  }
`

const MARK_AS_READ = gql`
  mutation MarkAsRead($id: ID!) {
    markNotificationAsRead(id: $id) {
      notification {
        id
        hasViewed
      }
    }
  }
`

const MARK_ALL_AS_READ = gql`
  mutation MarkAllAsRead($alertCategory: AlertCategoryFilterEnum) {
    markAllNotificationsAsRead(alertCategory: $alertCategory) {
      successful
    }
  }
`

const DESTROY = gql`
  mutation DestroyNotification($id: ID!) {
    destroyNotification(id: $id) {
      notification {
        id
      }
    }
  }
`
