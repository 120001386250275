import asyncComponent from '../../AsyncComponent'

export const ModeratedComments = asyncComponent(() =>
  import(/* webpackChunkName: "Comments" */ './ModeratedComments')
)

export const NewComments = asyncComponent(() =>
  import(/* webpackChunkName: "Comments" */ './NewComments')
)

export const CompletedComments = asyncComponent(() =>
  import(/* webpackChunkName: "Comments" */ './CompletedComments')
)
