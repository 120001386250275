import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { breadcrumbify } from 'redux-breadcrumb-trail'

PatchBreadcrumb.propTypes = {
  title: PropTypes.node
}

PatchBreadcrumb.defaultProps = {
  title: null
}

export function PatchBreadcrumb({ title }) {
  if (!title) {
    return <i className="fa fa-refresh fa-spin" />
  }

  return <span>{title}</span>
}

export const mapStateToProps = state => ({ title: state.breadcrumbPatch.title })
export default connect(mapStateToProps)(breadcrumbify(PatchBreadcrumb))
