import { dispatch, getState } from '../Store'
import { currentUser } from './opsApi'

export default async function redirectIfLoggedIn (nextState, replace, done) {
  const state = getState()
  const user = currentUser(state)

  if (user.initialized) {
    return done(replace('/'))
  }

  try {
    const res = await dispatch(currentUser.fetch())
    if (res.value.data.is_internal) replace('/')
  } catch (err) {}

  done()
}
