import React from 'react'
import PropTypes from 'prop-types'
import { formValues } from 'redux-form'
import isEqual from 'lodash/isEqual'

export class FormValue extends React.Component {
  componentDidUpdate(prevProps) {
    const { value, onUpdate } = this.props
    if (onUpdate && !isEqual(prevProps.value, value)) {
      onUpdate(value, prevProps.value)
    }
  }

  render() {
    const { children, value } = this.props
    return children({ value })
  }
}

const Enhanced = formValues(props => ({ value: props.name }))(FormValue)

Enhanced.propTypes = {
  children: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired
}

export default Enhanced
