import React from 'react'
import { string } from 'prop-types'
import { Field } from 'redux-form'
import ValidatedField from './ValidatedField'
import './ios7.css'

const switchStyle = { fontSize: '25px' }
export default class SwitchField extends React.Component {
  constructor() {
    super()
    this.renderSwitch = this.renderSwitch.bind(this)
  }

  renderSwitch(field) {
    const { name, placement, ...rest } = this.props

    return (
      <ValidatedField name={name} field={field} placement={placement}>
        <label className="ios7-switch" style={switchStyle}>
          <input
            type="checkbox"
            {...field.input}
            disabled={field.meta.submitting}
            checked={field.input.value}
            {...rest}
          />
          <span />{' '}
          {/* This useless element is required for ios7-switch to work */}
        </label>
      </ValidatedField>
    )
  }

  render() {
    return (
      <Field
        name={this.props.name}
        component={this.renderSwitch}
        normalize={this.props.normalize}
      />
    )
  }
}

SwitchField.propTypes = {
  name: string.isRequired,
}
