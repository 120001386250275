import React, { useEffect } from 'react'
import { any, array, bool, object, oneOfType } from 'prop-types'
import { QueryClient, QueryClientProvider } from 'react-query';
import { GraphQLClient } from 'graphql-request';
import { connect } from 'react-redux'
import Breadcrumbs from 'redux-breadcrumb-trail'
import { compose, withContext } from 'recompose'
import Config from '../Config'
import { CurrentUserContext } from '../hooks/useCurrentUser'
import { NotificationsContext } from '../hooks/useNotifications'
import { currentUser } from '../lib/opsApi'
import { getAuthToken } from '../lib/localStorage'
import PusherBinding from '../lib/bindPusher'
import { ReloadDialog } from './Common'
import Header from './Header'
import ErrorBoundary from './ErrorBoundary'
import { useNotificationsDetails } from '../hooks/useNotifications'

App.propTypes = {
  children: any.isRequired,
  routes: oneOfType([array, object]).isRequired,
  params: object.isRequired,
  isLoggedIn: bool.isRequired,
  router: object.isRequired,
  location: object.isRequired,
  currentUser: object,
}

App.defaultProps = {
  currentUser: null,
}

export function App(props) {
  const {
    children,
    location,
    router,
    routes,
    params,
    isLoggedIn,
    currentUser,
  } = props

  const notifications = useNotificationsDetails(router)
  const {
    notificationsQueries: {
      allCategoriesQuery: [getAllCategories, { refetch, called }]
    }
  } = notifications

  useEffect(() => {
    if (!isLoggedIn) return;

    called ? refetch() : getAllCategories()
  }, [currentUser, isLoggedIn, called, getAllCategories])

  const opsServiceHost = Config.opsServiceHost();
  const authToken = getAuthToken();
  const graphQLClient = React.useMemo(
    () => {
      const params = isLoggedIn ? {headers: { 'X-Auth-Token': authToken }} : {}
      return new GraphQLClient(`${opsServiceHost}/graphql`, params);
    },
    [
      opsServiceHost,
      authToken,
      isLoggedIn
    ]
  );
  const defaultQueryFn = async context => {
    const query = context.queryKey[0];
    const variables = context.queryKey[1] || {};
    return await graphQLClient.request(query, variables);
  };
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        queryFn: defaultQueryFn,
        // Prevent the aggressive cache management.
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        cacheTime: Infinity,
        staleTime: 600000
      }
    }
  });
  document.body.classList.add(`${process.env.REACT_APP_NODE_ENV}-background`);

  return (
    <QueryClientProvider client={queryClient}>
      <NotificationsContext.Provider value={notifications}>
        {isLoggedIn && <PusherBinding user={currentUser} />}
        <div className={'antialiased'}>
          <Header isLoggedIn={isLoggedIn} router={router} />
          <div
            id="snackbars"
            className="flex flex-col absolute mt-24 top-0 left-0 right-0 z-40 pointer-events-none"
          />

          <div className="container">
            {isLoggedIn && (
              <Breadcrumbs
                className="list-inline"
                location={location}
                params={params}
                routes={routes}
                separatorRenderer={<i className="fa fa-caret-right" />}
              />
            )}

            <CurrentUserContext.Provider value={currentUser}>
              <ErrorBoundary>
                {children}
              </ErrorBoundary>
            </CurrentUserContext.Provider>
          </div>

          <ReloadDialog />
        </div>
      </NotificationsContext.Provider>
    </QueryClientProvider>
  )
}

export const mapStateToProps = (state) => {
  const user = currentUser(state)
  const isLoggedIn = !!(
    user &&
    user.initialized &&
    user.payload.data.is_internal
  )
  return {
    currentUser: user.initialized ? user.payload.data : null,
    isLoggedIn,
  }
}

export const getChildContext = ({ currentUser }) => ({ currentUser })

export default compose(
  connect(mapStateToProps),
  withContext({ currentUser: object }, getChildContext)
)(App)
