import asyncComponent from '../AsyncComponent'

export const SearchPage = asyncComponent(() =>
  import(/* webpackChunkName: "PDM" */ './ContractSearch/SearchPage')
)

export const AddEditContract = asyncComponent(() =>
  import(/* webpackChunkName: "PDM" */ './AddEditContract')
)

export const ContractPage = asyncComponent(() =>
  import(/* webpackChunkName: "PDM" */ './ContractPage')
)

export const ManageAffiliates = asyncComponent(() =>
  import(/* webpackChunkName: "PDM" */ './Affiliates/ManageAffiliates')
)

export const AffiliateDetail = asyncComponent(() =>
  import(/* webpackChunkName: "PDM" */ './Affiliates/AffiliateDetail')
)

export const AffiliateProperties = asyncComponent(() =>
  import(/* webpackChunkName: "PDM" */ './Affiliates/AffiliateProperties')
)

export const AffiliateNetworks = asyncComponent(() =>
  import(/* webpackChunkName: "PDM" */ './Affiliates/AffiliateNetworks')
)

export const AffiliateDocuments = asyncComponent(() =>
  import(/* webpackChunkName: "PDM" */ './Affiliates/AffiliateDocuments')
)

export const ManageFeeSchedules = asyncComponent(() =>
  import(/* webpackChunkName: "PDM" */ './FeeSchedules/ManageFeeSchedules')
)

export const FeeSchedulePage = asyncComponent(() =>
  import(/* webpackChunkName: "PDM" */ './FeeSchedules/FeeSchedulePage')
)

export const FeeScheduleProperties = asyncComponent(() =>
  import(/* webpackChunkName: "PDM" */ './FeeSchedules/FeeScheduleProperties')
)

export const FeeScheduleDetails = asyncComponent(() =>
  import(/* webpackChunkName: "PDM" */ './FeeSchedules/FeeScheduleDetails')
)

export const ManageNetworks = asyncComponent(() =>
  import(/* webpackChunkName: "PDM" */ './Networks/ManageNetworks')
)

export const NetworkPage = asyncComponent(() =>
  import(/* webpackChunkName: "PDM" */ './Networks/NetworkPage')
)

export const NetworkAffiliatePage = asyncComponent(() =>
  import(/* webpackChunkName: "PDM" */ './NetworkAffiliates/NetworkAffiliatePage')
)

export const RateRulePage = asyncComponent(() =>
  import(/* webpackChunkName: "PDM" */ './NetworkAffiliates/RateRulePage')
)

export const ManageExclusions = asyncComponent(() =>
  import(/* webpackChunkName: "PDM" */ './Exclusions/ManageExclusions')
)
