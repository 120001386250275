// localStorage is a browser-specific global variable, for the sake of unit tests and old browsers,
// wrap these accesses in functions that check for the existence of the global first.
const AUTH_TOKEN_KEY = 'X-Auth-Token'

export function setAuthToken (token) {
  if (typeof localStorage !== 'undefined') {
    localStorage.setItem(AUTH_TOKEN_KEY, token)
  }
}

export function getAuthToken () {
  if (typeof localStorage !== 'undefined') {
    return localStorage.getItem(AUTH_TOKEN_KEY)
  }
}

export function deleteAuthToken () {
  if (typeof localStorage !== 'undefined') {
    return localStorage.removeItem(AUTH_TOKEN_KEY)
  }
}
