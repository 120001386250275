export const NEW_NOTIFICATION = 'NEW_NOTIFICATION'

export const notify = payload => ({ type: NEW_NOTIFICATION, payload })

export default function notification (state = {}, action) {
  switch (action.type) {
    case NEW_NOTIFICATION: {
      const { id, has_viewed, user_id, alert } = action.payload
      return {
        ...state,
        id,
        has_viewed,
        user_id,
        alert
      }
    }
    default:
      return state
  }
}
