import 'react-app-polyfill/ie11'
import 'babel-polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import toastr from 'toastr'
import Routes from './Routes'
import bindFilestack from './lib/bindFilestack'
import './vendor'

// Initialize Filestack
bindFilestack()

toastr.options = { positionClass: 'toast-bottom-left' }

ReactDOM.render(<Routes />, document.getElementById('app'))
