import isArray from 'lodash/isArray'
import { compose, branch, renderNothing } from 'recompose'
import withCurrentUser from './withCurrentUser'

export const HasFeature = props => props.children

export default compose(
  withCurrentUser,
  branch(({currentUser, feature: features}) => {
    if (!isArray(features)) {
      features = [features]
    }

    return !currentUser || features.every(feature => !currentUser.features.includes(feature))
  }, renderNothing)
)(HasFeature)
