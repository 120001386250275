import React from 'react'
import PropTypes from 'prop-types'
import Tooltip from '../Tooltip'
import { MatchHighlight } from '../withHighlight'

TooltipCell.propTypes = {
  column: PropTypes.object.isRequired,
  columnProps: PropTypes.object.isRequired,
  value: PropTypes.node,
  index: PropTypes.number
}

TooltipCell.defaultProps = {
  value: null,
  index: null
}

export default function TooltipCell(props) {
  const { column, columnProps, value, index } = props

  if (!value) return null

  const item = columnProps.rest.filtered.find(x => x.id === column.id)

  const highlighted = (
    <MatchHighlight match={item && item.value} children={props.value} />
  )

  return (
    <Tooltip id={`tooltipCell${index}`} title={highlighted} placement="left">
      {highlighted}
    </Tooltip>
  )
}
