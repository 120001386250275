import React from 'react'
import PropTypes from 'prop-types'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import withCurrentUser from './Common/withCurrentUser'

export function Profile (props) {
  const { currentUser, size } = props

  if (!currentUser || !currentUser.gravatar_url) {
    return null
  }

  return (
    <div>
      <OverlayTrigger
        placement='bottom'
        overlay={<Tooltip id='profileEmailTooltip'>{currentUser.email}</Tooltip>}
        rootClose
      >
        <img
          src={`${currentUser.gravatar_url}&s=${size}`}
          style={{width: size, height: size}}
          className='img-circle'
          alt=""
        />
      </OverlayTrigger>
    </div>
  )
}

Profile.propTypes = {
  currentUser: PropTypes.shape({
    gravatar: PropTypes.string,
    email: PropTypes.string
  }),
  size: PropTypes.number
}

Profile.defaultProps = {
  size: 40,
}

export default withCurrentUser(Profile)
