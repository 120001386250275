import React from 'react'
import PropTypes from 'prop-types'
import { query } from 'redux-bees'
import omit from 'lodash/omit'
import isEqual from 'lodash/isEqual'

export default (propName, apiCall, dispatcher, hoc=x=>x) => query(propName, apiCall, dispatcher)(hoc(
  /*
   * This is a render props component intended to expose the props of the query() HOC
   * as the parameters in the render prop function. Why is it so complicated? Because
   * when we transition to the next page, the transition is jarring because the HOC
   * stops rendering the old results as soon as it starts working on the new page.
   *
   * It would look better if it showed the old data while it was working on the new.
   *
   * To make that happen, this logic is intended to A) detect when a transition starts,
   * locking the old data in place, B) release the new data when the transition finishes.
   *
   * It's not quite so simple though, because there are parts of the new data state
   * that we still want to keep updated during the transition, like the `isLoading`
   * prop or AJAX errors. So we cherry pick a few props to update or not update when we
   * setState and hopefully all of that works correctly.
   *
   * I feel like this is more complicated than it needs to be, so suggestions/improvements
   * are encouraged! --Leebo
   */
  class QueryProvider extends React.Component {
    static propTypes = {
      children: PropTypes.func.isRequired,
      params: PropTypes.object,
      shouldFreezeData: PropTypes.func.isRequired
    }

    static defaultProps = {
      shouldFreezeData: (props, prevProps) => !isEqual(props.params, prevProps.params)
    }

    constructor(props) {
      super(props)
      const { children, shouldFreezeData, ...queryProps } = props
      this.state = { frozen: false, queryProps }
    }

    changeState = prevState => {
      const { children, shouldFreezeData, omitted: omittedProps=[], ...queryProps } = this.props

      if (isEqual(queryProps, prevState.queryProps)) {
        return null
      }

      const frozen = prevState.frozen
        ? queryProps.status[propName].isLoading
        : shouldFreezeData(queryProps, prevState.queryProps)

      const omitted = [propName, `status.${propName}.meta`, ...omittedProps]
      const partialMatch = isEqual(
        omit(queryProps, omitted),
        omit(prevState.queryProps, omitted)
      )

      if (!frozen || !partialMatch) {
        const updatedQueryProps = frozen
          ? {
              ...queryProps,
              [propName]: prevState.queryProps[propName],
              status: {
                [propName]: {
                  ...queryProps.status[propName],
                  meta: prevState.queryProps.status[propName].meta
                }
              }
            }
          : queryProps

        return { frozen, queryProps: updatedQueryProps }
      }

      return null
    }

    componentDidUpdate() {
      this.setState(this.changeState)
    }

    render() {
      return this.props.children(this.state.queryProps)
    }
  }
))
