import React from 'react'
import { withFeedback } from './FieldFeedback'
import './ios7.css'

const switchStyle = { marginTop: 1, marginBottom: 0 }
export default function Switch(props) {
  return (
    <label className="ios7-switch" style={switchStyle}>
      <input type="checkbox" {...props} />
      <span /> {/* This useless element is required for ios7-switch to work */}
    </label>
  )
}

const Enhanced = withFeedback(Switch)

Switch.Field = ({ input, ...rest }) => (
  <Enhanced {...rest} checked={input.value} onChange={input.onChange} />
)
