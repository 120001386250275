import asyncComponent from '../../AsyncComponent'

export const ProductDetailPage = asyncComponent(() =>
  import(/* webpackChunkName: "ProductDetail" */ './ProductDetailPage')
)

export const ProductChangesPage = asyncComponent(() =>
  import(/* webpackChunkName: "ProductDetail" */ './ProductChangesPage')
)

export const PropertiesTab = asyncComponent(() =>
  import(/* webpackChunkName: "ProductDetail" */ './PropertiesTab')
)

export const CarriersTab = asyncComponent(() =>
  import(/* webpackChunkName: "ProductDetail" */ './CarriersTab')
)

export const MappingsTab = asyncComponent(() =>
  import(/* webpackChunkName: "ProductDetail" */ './MappingsTab')
)

export const EmployersTab = asyncComponent(() =>
  import(/* webpackChunkName: "ProductDetail" */ './EmployersTab')
)

export const ContactsTab = asyncComponent(() =>
  import(/* webpackChunkName: "ProductDetail" */ './ContactsTab')
)

export const FlagsTab = asyncComponent(() =>
  import(/* webpackChunkName: "ProductDetail" */ './FlagsTab')
)

export const DocumentsTab = asyncComponent(() =>
  import(/* webpackChunkName: "ProductDetail" */ './DocumentsTab')
)

export const InternalDocumentsTab = asyncComponent(() =>
  import(/* webpackChunkName: "ProductDetail" */ './InternalDocumentsTab')
)

export const HistoryTab = asyncComponent(() =>
  import(/* webpackChunkName: "ProductDetail" */ './HistoryTab')
)
