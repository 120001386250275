import _ from 'lodash'

export default function pagedTransformer (payload, previous, isFulfilled, meta) {
  if (!isFulfilled) {
    return previous
  }

  const { action, inputData } = meta

  switch (action) {
    case 'update': {
      if (payload.status === 204) {
        return previous
      }

      const index = _.findIndex(previous.data.results, { id: payload.data.id })
      const results = [...previous.data.results]
      results[index] = payload.data

      return { ...previous, data: { ...previous.data, results } }
    }
    case 'destroy': {
      const results = _.filter(previous.data.results, ({id}) => id !== inputData.id)
      return { ...previous, data: { ...previous.data, results, total_count: previous.data.total_count - 1 } }
    }
    default:
      break
  }

  const appender = (obj, src) => {
    if (_.isArray(obj)) {
      return obj.concat(src)
    }
  }

  return _.mergeWith(previous, payload, appender)
}
