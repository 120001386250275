import asyncComponent from '../AsyncComponent'
//import lazyLoad from '../lazyLoad'

export const AuditReport = asyncComponent(() =>
  import(/* webpackChunkName: "Audits" */ './AuditReport')
)

export const AuditReportDetails = asyncComponent(() =>
  import(/* webpackChunkName: "Audits" */ './AuditReportDetails')
)
