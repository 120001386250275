import _ from 'lodash'
import React from 'react'
import { shape } from 'prop-types'
import { Link } from 'react-router'
import { Cell } from 'fixed-data-table-2'
import { dataContextShape } from '../DataContext'

export default function LinkCell (props, context) {
  const { rowIndex, column, to, ...rest } = props
  const { getObjectAt, version } = context.data
  const data = getObjectAt(rowIndex)

  if (!data) {
    return <Cell />
  }

  const val = _.isFunction(column)
    ? column(data)
    : _.get(data, column)

  const href = _.isFunction(to)
    ? to(data)
    : to

  return (
    <Cell version={version} {...rest}><Link to={href}>{val}</Link></Cell>
  )
}

LinkCell.contextTypes = { data: shape(dataContextShape) }
