import React from 'react'
import { Radio } from 'react-bootstrap'
import FieldFeedback from './FieldFeedback'

export default function RadioGroup(props) {
  const { name, onChange, value, options = [] } = props

  const radios = options.map(option => (
    <Radio
      key={option.value}
      name={name}
      onChange={onChange}
      value={option.value}
      checked={value === option.value}
      disabled={option.disabled}
      inline
    >
      {option.label}
    </Radio>
  ))

  for (let i = radios.length; i > 0; i--) {
    radios.splice(i, 0, ' ')
  }

  return radios
}

const feedbackStyle = { display: 'inline' }
const radioStyle = { marginLeft: 10 }
RadioGroup.Field = props => {
  const { input, meta, name, options = [] } = props
  const [head, ...tail] = options.map(option => (
    <Radio
      key={option.value}
      name={name}
      onChange={input.onChange}
      value={option.value}
      checked={input.value === option.value}
      disabled={option.disabled}
      style={radioStyle}
      inline
    >
      {option.label}
    </Radio>
  ))

  for (let i = tail.length; i > 0; i--) {
    tail.splice(i, 0, ' ')
  }

  return (
    <React.Fragment>
      <FieldFeedback meta={meta} style={feedbackStyle}>
        {head}
      </FieldFeedback>{' '}
      {tail}
    </React.Fragment>
  )
}
