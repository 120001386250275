import isArray from 'lodash/isArray'
import { getState } from '../Store'
import { currentUser } from './opsApi'

export default function requireRole(roles) {
  return (nextState, replace) => {
    const state = getState()
    const user = currentUser(state)

    if (!isArray(roles)) {
      roles = [roles]
    }

    if (user.initialized && roles.every(role => !user.payload.data.features.includes(role))) {
      return replace('/forbidden')
    }
  }
}
