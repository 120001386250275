import asyncComponent from '../../AsyncComponent'

export const QAReportsPage = asyncComponent(() =>
  import(/* webpackChunkName: "QAReports" */ './QAReportsPage')
)

export const EnrollmentTab = asyncComponent(() =>
  import(/* webpackChunkName: "QAReports" */ './EnrollmentTab')
)

export const FlagsTab = asyncComponent(() =>
  import(/* webpackChunkName: "QAReports" */ './FlagsTab')
)

export const TieringTab = asyncComponent(() =>
  import(/* webpackChunkName: "QAReports" */ './TieringTab')
)

export const GroupModelTab = asyncComponent(() =>
  import(/* webpackChunkName: "QAReports" */ './GroupModelTab')
)
