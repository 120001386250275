export const statusOptions = [
  {label: 'Created',
    value: 'created'},
  {label: 'In Progress',
    value: 'in_progress'},
  {label: 'Completed',
    value: 'completed'},
]

const initialState = {
  statusOptions,
  statusAllOptions: [
    {label: 'All',
      value: null},
    ...statusOptions,
  ],
}

export default function reducer (state = initialState, action) {
  switch (action.type) {
    default:
      return state
  }
}
