import { compose, renameProps, flattenProp } from 'recompose'
import { Checkbox } from 'react-bootstrap'
import { withFeedback } from './FieldFeedback'

Checkbox.Field = compose(
  flattenProp('input'),
  renameProps({ value: 'checked' }),
  withFeedback
)(Checkbox)

export default Checkbox
