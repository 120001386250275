const NEXT_PAGE = 'NEXT_PAGE'
const nextPage = () => ({ type: NEXT_PAGE })

export const versionSelector = (state, key = 'pages') => state[key].version

export const withResource = resource => {
  let busy = false
  const startSearch = payload => async dispatch => {
    busy = true
    await dispatch(resource.reset())
    await dispatch(resource.fetch(payload))
    await dispatch(nextPage())
    busy = false
  }

  const requestData = payload => async dispatch => {
    if (busy) {
      return
    }

    busy = true
    await dispatch(resource.fetch(payload))
    await dispatch(nextPage())
    busy = false
  }

  return {
    startSearch,
    requestData,
    versionSelector,
  }
}

const initialState = { version: 0 }
export default function reducer (state = initialState, action) {
  switch (action.type) {
    case NEXT_PAGE: return { version: state.version + 1 }
    default: return state
  }
}
