import _ from 'lodash'
import React from 'react'
import toastr from 'toastr'

export function getMessage(err) {
  return _.join(err, '')
}

export default class SubmitError extends React.PureComponent {
  static defaultProps = {
    title: 'Failure'
  }

  componentDidMount () {
    const { error, title } = this.props
    if (error) {
      toastr.error(getMessage(error), title)
    }
  }

  componentDidUpdate (prevProps) {
    const { error, title } = this.props
    if (error && prevProps.error !== error) {
      toastr.error(getMessage(error), title)
    }
  }

  render () {
    const { error } = this.props
    if (error) {
      const msg = getMessage(error)
      return msg ? <span className='text-danger'>{msg}</span> : null
    }

    return null
  }
}
