import asyncComponent from '../../AsyncComponent'

export const DataCollectionOutreach = asyncComponent(() =>
  import(/* webpackChunkName: "Outreaches" */ './DataCollectionOutreach')
)

export const DataCollectionOutreaches = asyncComponent(() =>
  import(/* webpackChunkName: "Outreaches" */ './DataCollectionOutreaches')
)

export const DataCollectionOutreachActivities = asyncComponent(() =>
  import(/* webpackChunkName: "Outreaches" */ './DataCollectionOutreachActivities')
)

export const OutreachContainer = asyncComponent(() =>
  import(/* webpackChunkName: "Outreaches" */ './OutreachContainer')
)

export const OutreachBreadcrumb = asyncComponent(() =>
  import(/* webpackChunkName: "Outreaches" */ './OutreachBreadcrumb')
)

export const DataCollectionProducts = asyncComponent(() =>
  import(/* webpackChunkName: "Outreaches" */ './DataCollectionProducts')
)
