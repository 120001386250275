import React from 'react'
import PropTypes from 'prop-types'
import * as BS from 'react-bootstrap'

FormField.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  labelCols: PropTypes.number,
  fieldCols: PropTypes.number
}

FormField.defaultProps = {
  labelCols: 4,
  fieldCols: 8
}

export default function FormField({
  label,
  children,
  labelCols,
  fieldCols,
  ...rest
}) {
  return (
    <BS.FormGroup>
      <BS.Col componentClass={BS.ControlLabel} xs={labelCols} {...rest}>
        {label}
      </BS.Col>
      <BS.Col xs={fieldCols}>{children}</BS.Col>
    </BS.FormGroup>
  )
}
