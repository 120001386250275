import { Component } from 'react'
import { func, number, shape } from 'prop-types'
import { connect } from 'react-redux'
import { versionSelector } from '../../redux/reducers/pages'

export const dataContextShape = {
  getObjectAt: func.isRequired,
  version: number.isRequired,
}

export class DataContext extends Component {
  static propTypes = {
    ...dataContextShape,
  }

  static childContextTypes = {
    data: shape(dataContextShape),
  }

  getChildContext = () => {
    const { getObjectAt, version } = this.props
    return { data: { getObjectAt, version } }
  }

  render = () => this.props.children
}

export const mapProps = state => ({ version: versionSelector(state) })

export default connect(mapProps)(DataContext)
