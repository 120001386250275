import React, { memo, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import toastr from 'toastr'
import { Button } from 'react-bootstrap'

Retry.propTypes = {
  onRetry: PropTypes.func.isRequired
}

function Retry({onRetry}) {
  useEffect(() => {
    toastr.error("The server responded with an error.")
  }, [])

  const handleRetry = useCallback(() => {
    onRetry()
  }, [onRetry])

  return (
    <div className="text-center">
      <h1><i className="fa fa-frown-o" /></h1>
      <h5><i>It didn&apos;t work.</i></h5>

      <Button bsSize="sm" onClick={handleRetry}>Retry</Button>
    </div>
  )
}

export default memo(Retry)
