import { dispatch, getState } from '../Store'
import { currentUser } from '../lib/opsApi'
import { setNextLocation } from '../redux/modules/authentication'
import { getAuthToken, deleteAuthToken } from './localStorage'

export default async function requireLogin(nextState, replace, done) {
  const state = getState()
  const user = currentUser(state)
  if (!user.initialized) {
    dispatch(setNextLocation(nextState.location))

    const token = getAuthToken()
    if (token) {
      try {
        const res = await dispatch(currentUser.fetch())
        const { id, email, is_internal } = res.value.data
        global.Raygun.setUser(email)

        if (!is_internal) {
          replace('/login')
          const err = `${email} (${id}) attempted to login as an unauthorized user.`
          console.error(err) // eslint-disable-line no-console
          return done()
        }

        const { pathname='/', search='' } = nextState.location
        replace({ pathname, search, state: { breadcrumb: 'reset' } })
        return done()
      } catch (error) {
        deleteAuthToken()
        replace(`/login?error=${encodeURIComponent(error)}`)
        return done()
      }
    }

    replace('/login')
  } else if (!user.payload.data.is_internal) {
    const error = encodeURIComponent('Access denied')
    replace(`/login?error=${error}`)
  }

  done()
}
