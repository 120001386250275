import React from 'react'
import { bool, func, node, oneOfType, string } from 'prop-types'
import { Modal } from 'react-bootstrap'

export default function AcknowledgeDialog (props) {
  const {
    show,
    onConfirm,
    title,
    body,
    confirmLabel,
    showTopCloseButton,
    isLarge
  } = props

  const bsSize = isLarge ? "large" : undefined
  const ariaLabelledby = isLarge ? "contained-modal-title-lg" : undefined

  return (
    <Modal show={show} onHide={onConfirm} bsSize={bsSize} aria-labelledby={ariaLabelledby}>
      <Modal.Header closeButton={showTopCloseButton}>
        <Modal.Title>
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {body}
      </Modal.Body>
      <Modal.Footer>
        <button className='btn btn-primary' autoFocus onClick={onConfirm}>
          {confirmLabel}
        </button>
      </Modal.Footer>
    </Modal>
  )
}

AcknowledgeDialog.propTypes = {
  title: string.isRequired,
  body: oneOfType([string, node]).isRequired,
  show: bool.isRequired,
  onConfirm: func.isRequired,
  confirmLabel: string,
  showTopCloseButton: bool,
  isLarge: bool
}

AcknowledgeDialog.defaultProps = {
  confirmLabel: "OK",
  showTopCloseButton: false,
  isLarge: false
}
