import React from 'react'
import ReactDOM from 'react-dom'
import PT from 'prop-types'
import Transition from './Transition'

Snackbar.propTypes = {
  show: PT.bool.isRequired,
  type: PT.string,
  onClose: PT.func,
  children: PT.node,
}

Snackbar.defaultProps = {
  type: 'default',
  onClose: null,
  children: null,
}

export default function Snackbar({ show, type, onClose, children }) {
  const el = document.getElementById('snackbars')
  if (!el) return null

  return ReactDOM.createPortal(
    <div className="flex justify-center container">
      <Transition
        show={show}
        appear
        enter="transition ease-out duration-100 transform"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-75 transform"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div
          className={`pointer-events-auto max-w-6xl alert alert-${type} ${
            onClose ? 'alert-dismissible' : ''
          }`}
          style={{ marginTop: 0, marginBottom: '0.5rem' }}
        >
          {onClose && (
            <button
              type="button"
              className="ml-8 close"
              aria-label="Close"
              onClick={onClose}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          )}
          {children}
        </div>
      </Transition>
    </div>,
    el
  )
}
