/*
 * This is a temporary reducer so that I can create breadcrumbs for components that are not storing their
 * models in redux. Once everything has been converted to use redux-supermodel and redux for resource state,
 * this reducer should be removed.
 */
const initialState = {
  title: null,
}

export const SET = 'SET_CUSTOM_BREADCRUMB_TITLE'

export function setBreadcrumb (title = null) {
  return { type: SET, payload: title }
}

export default function breadcrumbPatch (state = initialState, action) {
  switch (action.type) {
    case SET:
      return { title: action.payload }
    default:
      return state
  }
}
