import asyncComponent from '../AsyncComponent'

export const MachineReadableFileBreadcrumb = asyncComponent(() =>
  import(/* webpackChunkName: "MachineReadableFiles" */ './MachineReadableFileBreadcrumb')
)

export const MachineReadableFileHistoryBreadcrumb = asyncComponent(() =>
  import(/* webpackChunkName: "MachineReadableFiles" */ './MachineReadableFileHistoryBreadcrumb')
)

export const MachineReadableFileMapDetail = asyncComponent(() =>
  import(/* webpackChunkName: "MachineReadableFiles" */ './MachineReadableFileMapDetail')
)

export const MachineReadableFileMapHistory = asyncComponent(() =>
  import(/* webpackChunkName: "MachineReadableFiles" */ './MachineReadableFileMapHistory')
)

export const MachineReadableFileMapList = asyncComponent(() =>
  import(/* webpackChunkName: "MachineReadableFiles" */ './MachineReadableFileMapList')
)
