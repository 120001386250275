import React from 'react'

const debugMessage = (error, info) => {
  if (process.env.NODE_ENV === 'development') {
    return (
      <div>
        {error && (
          <React.Fragment>
            <h5>Error message:</h5>
            <pre><code>{error.message}</code></pre>

            <h5>Error stack:</h5>
            <pre><code>{error.stack}</code></pre>
          </React.Fragment>
        )}

        {info && (
          <React.Fragment>
            <h5>Component stack:</h5>
            <pre><code>{info.componentStack}</code></pre>
          </React.Fragment>
        )}
      </div>
    )
  }

  return null
}

export default function ErrorPanel ({error, info, onClose}) {
  return (
    <div className='panel panel-danger'>
      <div className='panel-heading'>
        <h4>An error occurred <button onClick={onClose} type="button" className='close pull-right'>&times;</button></h4>
      </div>
      <div className='panel-body'>
        <p>
          <b>Well, <em>that</em> didn&apos;t work.</b> The application crashed. But don&apos;t worry, we&apos;ve already notified the people in charge. Try <a href='/'>refreshing the page</a>.
        </p>

        {debugMessage(error, info)}
      </div>
    </div>
  )
}
