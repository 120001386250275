import { getState } from '../Store'
import { currentUser } from './opsApi'

export default function (_nextState, replace) {
  const state = getState()
  const user = currentUser(state)
  if (user.payload.data.is_global_user) {
    return replace('/forbidden')
  }
}
