import _ from 'lodash'
import moment from 'moment-timezone/moment-timezone'
import timezones from './us-timezones.json' // custom timezone JSON with only US/* timezones

moment.tz.load(timezones);

export const ORGANIZATION_SELECTION_CHANGE = 'ops/manageUsers/ORGANIZATION_SELECTION_CHANGE'

const usOnly = tz => _.startsWith(tz, 'US/')
const mapOption = tz => ({ key: tz, value: tz, label: tz })
const initialState = {
  selectedOrganization: 12,
  timeZones: _(moment.tz.names()).filter(usOnly).map(mapOption).value()
}

export default function reducer (state = initialState, action) {
  switch (action.type) {
    case ORGANIZATION_SELECTION_CHANGE:
      return Object.assign({}, state, { selectedOrganization: action.data })
    default:
      return state
  }
}

export function changeOrganizationSelection (data) {
  return {
    type: ORGANIZATION_SELECTION_CHANGE,
    data,
  }
}
