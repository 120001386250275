import asyncComponent from '../../AsyncComponent'

export const ServiceAreas = asyncComponent(() =>
  import(/* webpackChunkName: "ServiceAreas" */ './ServiceAreas')
)

export const ServiceArea = asyncComponent(() =>
  import(/* webpackChunkName: "ServiceAreas" */ './ServiceArea')
)

export const ServiceAreaProperties = asyncComponent(() =>
  import(/* webpackChunkName: "ServiceAreas" */ './ServiceAreaProperties')
)

export const ServiceAreaConflictRules = asyncComponent(() =>
  import(/* webpackChunkName: "ServiceAreas" */ './ServiceAreaConflictRules')
)

export const BulkAddConflictRules = asyncComponent(() =>
  import(/* webpackChunkName: "ServiceAreas" */ './BulkAddConflictRules')
)
