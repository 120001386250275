import React from 'react'
import ResourceError from './Common/ResourceError'

export default function ErrorPage (props) {
  const {
    location: { state: { error } = {} } = {},
  } = props

  if (error) {
    console.log(error) // eslint-disable-line no-console
  }

  return (
    <div className='panel panel-danger'>
      <div className='panel-heading'>
        An error occurred
      </div>
      <div className='panel-body'>
        <ResourceError error={error || 'We\'re sorry, the application stopped working.'} />
      </div>
    </div>
  )
}
