import React from 'react'
import { connect } from 'react-redux'
import AcknowledgeDialog from './AcknowledgeDialog'

export function ReloadDialog ({reloadRequired}) {
  const handleConfirm = () => window.location.href = '/'

  // Render HTML formatting that is part of the message.
  const body = <span dangerouslySetInnerHTML={{ __html: reloadRequired.message }} />

  return (
    <AcknowledgeDialog
      show={reloadRequired.received}
      title='Reload Required'
      body={body}
      confirmLabel='Reload now'
      onConfirm={handleConfirm}
    />
  )
}

export const mapStateToProps = ({reloadRequired}) => ({reloadRequired})
export default connect(mapStateToProps)(ReloadDialog)
