import 'raygun4js'

const includeRaygun =  !['development', 'test'].includes(process.env.REACT_APP_NODE_ENV)

if (includeRaygun) {
  global.Raygun.init('o6YWGS1pu/q9Umj8U+rpKg==').whitelistCrossOriginDomains(['localhost:8080']).attach()
}

const crashReporter = store => next => action => {
  try {
    return next(action)
  } catch (err) {
    if (includeRaygun) {
      global.Raygun.send(err, {
        action: action,
        state: store.getState()
      })
    } else {
      console.error(err)
    }
  }
}

export default crashReporter
