/*
 * This provider fetches relationships for JSONAPI objects.
 * https://jsonapi.org/format/#document-resource-object-relationships
 *
 * Did you came here to see if this code could get YOU a relationship?
 *
 * Okay, I'll be your friend! 😊🤝🤗
 */
import { connect } from 'react-redux'
import { getRelationship } from 'redux-bees'

const mapProps = (state, props) => ({
  [props.name]: getRelationship(state, props.owner, props.name)
})

export default connect(mapProps)(({children, ...rest}) => children(rest))
