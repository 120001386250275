const style = {
  control: {
    backgroundColor: '#fff',

    fontSize: 14,
    fontWeight: 'normal',
  },

  input: {
    margin: 0,
  },

  '&singleLine': {
    height: 34,
    margin: 0,
    padding: 0,

    control: {
      display: 'inline-block',
      width: '100%',
    },

    highlighter: {
      display: 'none',
    },

    input: {
      margin: 0,
      padding: '6px 12px',
      border: '1px solid #ccc',
      minHeight: 20,
      bottom: 0,
      color: '#555555',
    },
  },

  '&multiLine': {
    control: {
      fontFamily: 'monospace',
      border: '1px solid silver',
    },

    highlighter: {
      padding: 9,
    },

    input: {
      padding: 9,
      minHeight: 63,
      outline: 0,
      border: 0,
    },
  },

  suggestions: {
    list: {
      maxWidth: '500px',
      backgroundColor: 'white',
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: 10,
    },

    item: {
      padding: '5px 15px',
      borderBottom: '1px solid rgba(0,0,0,0.15)',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',

      '&focused': {
        backgroundColor: '#ebf5ff',
      },
    },
  },
}

export default style
