import React from 'react'
import { branch, mapProps, renderComponent } from 'recompose'
import Loader from './Loader'
import ErrorPanel from '../ErrorPanel'

export default opts => BaseComponent => {
  const {
    fetch,
    when=(props, nextProps) => nextProps === undefined
  } = opts

  return class Fetcher extends React.Component {
    state = {
      loading: true,
      results: [],
      fetchError: null
    }

    componentDidMount() {
      this._isMounted = true

      if (when(this.props)) {
        this.calculateState.call(this, this.props)
      } else {
        this.setState({ loading: false })
      }
    }

    componentDidUpdate(prevProps) {
      if (when(prevProps, this.props)) {
        this.calculateState.call(this, prevProps, this.props)
      }
    }

    componentWillUnmount() {
      this._isMounted = false
    }

    calculateState = (props, nextProps) => {
      this.setState({ loading: true })
      fetch(props, nextProps).then(
        (...results) => this._isMounted && this.setState({ loading: false, fetchError: null, results }),
        fetchError => this._isMounted && this.setState({ loading: false, fetchError })
      )
    }

    render() {
      return <BaseComponent {...this.props} refresh={() => this.calculateState(this.props)} {...this.state} />
    }
  }
}

export const FetchError = mapProps(props => ({
  error: props.fetchError,
  onClose() { props.refresh() }
}))(ErrorPanel)

/*
 * Be careful using these HOCs on one of those monolithic components with a lot of nesting async callbacks
 * and this.setState() calls, because if an action puts the component into one of these states, the
 * component will unmount, causing the setState to fail.
 *
 * Break large components up by moving some of their responsibilities into Higher Order Components using
 * recompose, then your setState behavior will be safe so long as you compose that HOC before you compose
 * these.
 *
 * To safeguard a component that is too complex to easily break down, you will want to put these safeguards
 * at the front of the render() function:

    const { loading, fetchError, refresh } = this.props
    if (loading) return <Loader />
    if (fetchError) return <ErrorPage error={fetchError} onClose={refresh} />

    // ... render as usual
 */
export const showLoader = branch(props => props.loading, renderComponent(Loader))
export const showError = branch(props => props.fetchError, renderComponent(FetchError))
