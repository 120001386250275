import _ from 'lodash'
import axios from 'axios'
import {
  createClient,
  collectionTransform as transform,
} from 'redux-supermodel'
import { SubmissionError } from 'redux-form'
import qs from 'qs'
import Config from '../Config'
import { getState } from '../Store'
import pagedTransformer from './pagedTransformer'
import collectionTransformer from './collectionTransformer'
import { getAuthToken } from './localStorage'

function reduxFormErrorInterceptor(err) {
  // Do not intercept GETs because they likely won't be redux-form submissions
  if (err.config && err.config.method === 'get') throw err

  const data = _.omit(_.get(err, 'response.data'), 'errors')
  if (err.errors && err.errors._error) {
    err = err.errors._error
  }

  if (err.response) {
    err = err.response && err.response.data
  } else if (err.code) {
    err = err.code
  }

  err = (err && err.full_errors) || err
  err = (err && err.errors) || err
  err = (err && err._error) || err.message || err

  // Field-based validation errors need to be returned as an object
  let error = undefined
  if (_.isPlainObject(err)) {
    error = new SubmissionError(err)
  } else {
    error = new SubmissionError({ _error: err })
  }
  if (data) error.data = data
  throw error
}

axios.interceptors.response.use((res) => res, reduxFormErrorInterceptor)

export const client = axios.create({
  baseURL: Config.opsServiceHost(),
  paramsSerializer: function (params) {
    return qs.stringify(params, { arrayFormat: 'brackets' })
  },
})

export const tokenAuthentication = (config) => {
  const state = getState()
  const { initialized, payload } = currentUser(state)

  let token
  if (initialized && payload.data.authentication_token) {
    token = payload.data.authentication_token
  }

  if (!token) {
    token = config.data && config.data.authentication_token
  }

  if (!token) {
    token = getAuthToken()
  }

  if (!token) {
    return config
  }

  const headers = { ...config.headers, 'X-Auth-Token': token }
  return token ? { ...config, headers } : config
}

client.interceptors.request.use(tokenAuthentication)
client.interceptors.response.use((res) => res, reduxFormErrorInterceptor)

export const createResource = createClient(Config.opsServiceHost(), {
  agent: axios,
  before: tokenAuthentication,
})

export function createCollection(name, options = {}) {
  const config = _.merge(options, {
    transform: collectionTransformer,
    defaultPayload: { data: [] },
  })
  return createResource(name, config)
}

// Replaces createCollection, but there may cause breaking changes so isolate this for now
export function createRailsCollection(name, options = {}) {
  const config = _.merge(options, { transform, defaultPayload: { data: [] } })
  return createResource(name, config)
}

export function createPagedCollection(name, options = {}) {
  const config = _.merge(options, { transform: pagedTransformer })
  return createResource(name, config)
}

// Users
export const currentUser = createResource('currentUser', {
  urlRoot: ({ data, method }) => (method === 'get' ? 'users/current' : 'users'),
})
export const user = createResource('user', { urlRoot: 'users' })
export const users = createCollection('users', { urlRoot: 'users' })
export const dcms = createCollection('dcm', {
  urlRoot: 'users/carrier_relationship_manager',
})
export const doms = createCollection('dom', { urlRoot: 'users/data_manager' })
export const staff = createCollection('staff', { urlRoot: 'users/staff' })

export const submitConfirmation = (data) =>
  client.patch('users/confirmation', data)

export const submitPasswordReset = (data) =>
  client.patch('users/reset_password', data)

export const isPdmUser = (user) =>
  user &&
  user.initialized &&
  user.payload.data.features.length &&
  user.payload.data.features.every((x) => x === 'pdm' || x === 'pdm_editor')

// Projects
export const projects = createCollection('project', {
  urlRoot: 'projects',
  idAttribute: 'project_number',
})
export const fetchProjectImports = () => client.get('project_import_statuses')
export const createImportCancelRequest = ({ job_id, status }) =>
  client.post(`project_import_cancel_request/${job_id}/${status}`)

// Organizations
export const organization = createResource('organization', {
  urlRoot: 'organizations',
  rootParam: true,
})
export const organizations = createResource('organizations', {
  urlRoot: 'organizations',
  rootParam: 'organization',
})
export const allOrganizations = createCollection('allOrganizations', {
  url: 'organizations?all_status=true',
})
export const clients = createCollection('client', {
  url: 'organizations?is_client=true',
})
export const suppliers = createCollection('supplier', {
  url: 'organizations?is_supplier=true',
})
export const projectManagers = createRailsCollection('projectManagers', {
  urlRoot: 'users/project_manager',
})

// Employers
export const employers = createRailsCollection('employers', {
  urlRoot: 'employers',
  rootParam: 'employer',
})
export const employerOrganization = createResource('employer_organization', {
  urlRoot: 'employer_organizations',
  rootParam: true,
})

// Contacts
export const organizationContacts = createResource('organizationContacts', {
  urlRoot: ({ data, method }) =>
    method === 'get'
      ? `organization_contacts/organization/${data.organization_id}`
      : 'organization_contacts',
  rootParam: 'organization_contact',
})

// Sources
export const sources = createPagedCollection('sources', { urlRoot: 'sources' })
export const sourceList = createResource('sourceList', {
  urlRoot: 'list/sources',
})
export const organizationSources = createResource('organizationSources', {
  url: ({ data }) => `organizations/${data.organization_id}/sources`,
})
export const products = createResource('products', {
  url: ({ data }) =>
    data.source_id ? `sources/${data.source_id}/products` : 'products',
})
export const productServiceAreas = createResource('productServiceAreas', {
  url: 'product_service_areas',
})

// Alerts
export const alerts = createResource('alert', {
  urlRoot: 'alerts',
  rootParam: true,
})
export const notifications = createCollection('notification', {
  urlRoot: 'notifications',
  rootParam: true,
})
export const bookmarks = createCollection('bookmark', {
  urlRoot: 'bookmarks',
  rootParam: true,
})

// Collections
export const effort = createResource('effort', {
  urlRoot: 'data_collection_efforts',
  rootParam: 'data_collection_effort',
})
export const dataCollectionEfforts = createPagedCollection('efforts', {
  urlRoot: 'data_collection_efforts',
})
export const dataCollectionEffortGenerations = createResource(
  'effortGeneration',
  {
    urlRoot: ({ data }) =>
      `data_collection_efforts/${data.data_collection_effort_id}/generations`,
    rootParam: 'data_collection_effort_generation',
  }
)

export const dceGenerations = createCollection('dceGeneration', {
  urlRoot: 'data_collection_effort_generations?with_active_effort=true',
})

export const dceFtpConfig = createResource('dceFtpConfig', {
  url: ({ data, method }) =>
    `data_collection_efforts/${data.data_collection_effort_id}/${
      method === 'post' ? 'ftp_test' : 'file_configurations'
    }`,
})

export const ftpTest = createResource('ftpTest', {
  url: 'ftp_configurations/test',
  rootParam: 'ftp_configuration',
})

export const emailConfigurations = createRailsCollection(
  'email_configurations',
  { urlRoot: 'email_configurations', rootParam: 'email_configuration' }
)
export const ftpConfiguration = createResource('ftp_configuration', {
  urlRoot: 'ftp_configurations',
  rootParam: true,
})
export const ftpConfigurations = createRailsCollection('ftp_configurations', {
  urlRoot: 'ftp_configurations',
  rootParam: 'ftp_configuration',
})
export const websiteConfigurations = createRailsCollection(
  'website_configurations',
  { urlRoot: 'website_configurations', rootParam: 'website_configuration' }
)
export const supplierContactReminder = createResource(
  'supplierContactReminder',
  {
    url: ({ data }) =>
      `organization_contact_data_collection_efforts/${data.id}/send_reminder`,
  }
)

// Outreaches
export const outreach = createResource('outreach', {
  urlRoot: 'data_collection_outreaches',
  rootParam: 'data_collection_outreach',
})
export const outreaches = createRailsCollection('outreaches', {
  urlRoot: 'data_collection_outreaches',
  rootParam: 'data_collection_outreach',
})
export const outreachList = createResource('outreachList', {
  url: 'list/outreaches',
})

// Processing
export const projectMapping = createResource('project_mapping', {
  urlRoot: 'project_mappings',
  rootParam: true,
})
export const projectMappings = createRailsCollection('project_mappings', {
  urlRoot: 'project_mappings',
  rootParam: 'project_mapping',
})

export const clientProductProjectMappings = createRailsCollection(
  'project_mapping_client_products',
  {
    urlRoot: 'project_mapping_client_products',
    rootParam: 'project_mapping_client_product',
  }
)

export const updateProjectMapping = (project_mapping) =>
  client.put(`project_mappings/${project_mapping.id}`, { project_mapping })

export const updateProjectMappingClientProductStatuses = (project_mapping) =>
  client.put(`project_mapping_client_products/bulk_status_update`, {
    project_mapping,
  })

export const createProjectMappingGeneration = (project_mapping_generation) =>
  client.post('project_mapping_generations', { project_mapping_generation })

export const clientProduct = createResource('clientProduct', {
  urlRoot: 'client_products',
  rootParam: 'client_product',
})

export const clientProducts = createRailsCollection('clientProducts', {
  urlRoot: 'client_products',
  rootParam: 'client_product',
})

export const clientProductCompletedRequestList = createRailsCollection(
  'clientProductCompletedRequestList',
  {
    urlRoot: 'list_completed/client_products',
    rootParam: 'client_product',
  }
)

export const clientProductCurrentRequestList = createRailsCollection(
  'clientProductCurrentRequestList',
  {
    urlRoot: 'list_current/client_products',
    rootParam: 'client_product',
  }
)

export const projectMappingGeneration = createResource(
  'projectMappingGeneration',
  {
    rootParam: 'project_mapping_generation',
    urlRoot: 'project_mapping_generations',
  }
)

export const projectMappingGenerations = createCollection(
  'projectMappingGenerations',
  {
    rootParam: 'project_mapping_generation',
    urlRoot: ({ data, method }) =>
      method === 'get' && data.project_mapping_id
        ? `project_mappings/${data.project_mapping_id}/generations`
        : 'project_mapping_generations',
  }
)

export const projectMappingClientProducts = createRailsCollection(
  'projectMappingClientProducts',
  {
    rootParam: 'project_mapping_client_product',
    urlRoot: 'project_mapping_client_products',
  }
)

export const employerClientProducts = createRailsCollection(
  'employerClientProducts',
  {
    rootParam: 'employer_client_product',
    urlRoot: 'employer_client_products',
  }
)

export const addClientProductEmployer = (employer_client_product) =>
  client.post('employer_client_products', { employer_client_product })

export const removeClientProductEmployer = (id) =>
  client.delete(`employer_client_products/${id}`)

export const pmGenerationActivities = createCollection(
  'pmGenerationActivities',
  {
    url: ({ data }) => `project_mappings/${data.project_mapping_id}/activities`,
  }
)
export const pmGenerationComparisonProducts = createCollection(
  'pmGenerationComparisonProducts',
  {
    url: ({ data }) =>
      `project_mapping_generations_comparison/${data.project_mapping_generation_id}/${data.project_mapping_generation_id2}`,
  }
)
export const networkMapping = createResource('network_mapping', {
  urlRoot: 'network_mappings',
  rootParam: true,
})
networkMapping.addVersion = ({ id, version_id, reason }) =>
  client.put(`network_mappings/${id}/versions/${version_id}`, {
    id,
    version_id,
    network_mapping_version: {
      reason,
    },
  })
networkMapping.createDraft = ({ id }) =>
  client.post(`network_mappings/${id}/versions`, { id })
networkMapping.cancelDraft = ({ id, version_id }) =>
  client.delete(`network_mappings/${id}/versions/${version_id}`, {
    id,
    version_id,
  })

export const networkMappings = createRailsCollection('network_mappings', {
  urlRoot: 'network_mappings',
  rootParam: 'network_mapping',
})

// Harvesting
export const mrf = createResource('mrf', {
  urlRoot: 'machine_readable_file_maps',
  rootParam: 'machine_readable_file_map',
})
export const mrfs = createRailsCollection('mrfs', {
  urlRoot: 'machine_readable_file_maps',
  rootParam: 'machine_readable_file_map',
})

export const mrfSources = createResource('mrfSources', {
  url: 'list/sources?is_mrf=true',
})
export const sourceProcedures = createResource('procedure', {
  url: ({ data }) => `sources/${data.sourceId}/procedures`,
})

// Comment Moderation
export const moderatedComments = createPagedCollection('moderatedComments', {
  urlRoot: 'moderated_comments',
})
export const moderatedComment = createResource('moderatedComment', {
  urlRoot: 'moderated_comments',
})

export default createResource
