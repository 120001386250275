import React from 'react'
import { compose, withState, withHandlers, lifecycle } from 'recompose'
import { connect } from 'react-redux'
import { NavLink } from 'redux-breadcrumb-trail'
import { getAuthToken } from '../lib/localStorage'
import { currentUser, isPdmUser } from '../lib/opsApi'
import { HasFeature, nonOptimalStates } from './Common'

export const Home = () => (
  <>
    <h3 className="page-header">Ops Prime Features</h3>

    <div className="grid grid-cols-3 gap-4">
      <HasFeature feature={['pdm', 'pdm_editor']}>
        <div>
          <NavLink to="/pdm/contracts/search">
            <b>Manage Contracts</b>
          </NavLink>
          <ul>
            <li>View by Provider, Location, Tax ID, Network, etc</li>
            <li>View contract details</li>
            <li>
              <NavLink to="/pdm/networks">View Networks</NavLink>
            </li>
          </ul>
        </div>
      </HasFeature>

      <HasFeature feature="projects">
        <div>
          <NavLink to="/n360jobmonitor">
            <b>N360 Job Monitor</b>
          </NavLink>
          <ul>
            <li>View import status for projects</li>
          </ul>
        </div>
      </HasFeature>

      <HasFeature feature="collections">
        <div>
          <NavLink to="/datacollection/efforts">
            <b>Manage Data Collection Efforts</b>
          </NavLink>
          <ul>
            <li>Create and manage an ongoing Data Collection Effort</li>
            <li>Configure carrier contact and file details</li>
            <li>Link to important documentation and view effort history</li>
            <li>Track Due Dates and Status</li>
            <li>
              <NavLink to="/datacollection/file_import_management">
                Monitor supplier-driven file imports
              </NavLink>
            </li>
            <li>
              <NavLink to="/datacollection/specialty_aliases">
                Change Specialty and Specialty Aliases associated with a File Import type
              </NavLink>
            </li>
          </ul>
        </div>

        <div>
          <NavLink to="/datacollection/outreaches">
            <b>Manage Outreaches</b>
          </NavLink>
          <ul>
            <li>Create and manage Outreaches</li>
            <li>
              View a listing of all Outreaches, filtered by DCM and Status
            </li>
            <li>
              Details include Client, Client Product Id, Carrier and Carrier
              Product Name, and Employer
            </li>
            <li>Navigate to Product Request properties</li>
            <li>Track Due Dates and Status</li>
          </ul>
        </div>

        <div>
          <NavLink to="/datacollection/products">
            <b>Product Dashboard</b>
          </NavLink>
          <ul>
            <li>Manage Product Requests</li>
            <li>View by Carrier, Client and Employer</li>
            <li>Assign to an Outreach</li>
            <li>
              View Carrier contact information, product request properties,
              activity stream, and communication between Zelis and the Client
            </li>
            <li>Track Due Dates and Status</li>
          </ul>
        </div>
      </HasFeature>

      <div>
        <NavLink to="/organizations">
          <b>Manage Organizations</b>
        </NavLink>
        <ul>
          <li>Add new organizations</li>
          <li>Edit existing organizations</li>
          <li>Create parent-child relationships</li>
          <li>Add or remove sources from supplier organizations</li>
          <li>List associated users</li>
        </ul>
      </div>

      <div>
        <NavLink to="/sources">
          <b>Manage Sources</b>
        </NavLink>
        <ul>
          <li>View source properties</li>
          <li>List associated users</li>
        </ul>
      </div>

      <HasFeature feature="users">
        <div>
          <NavLink to="/users">
            <b>Manage Users</b>
          </NavLink>
          <ul>
            <li>Create and manage internal and external users</li>
            <li>View by Organization and Status</li>
            <li>Activate, inactivate and assign roles</li>
            <li>Remove unconfirmed users</li>
            <li>Resend confirmation email</li>
          </ul>
        </div>
      </HasFeature>
    </div>
  </>
)

export const goToLogin = (props) => (opts) => {
  props.resetCurrentUser()
  props.router.push({ pathname: '/login', ...opts })
}

export const goToPDMSearch = (props) => (opts) => {
  props.router.replace({ pathname: '/pdm/contracts/search', ...opts })
}

export const mapProps = (state) => ({
  token: getAuthToken(),
  currentUser: currentUser(state),
})

const actions = {
  fetchCurrentUser: currentUser.fetch,
  resetCurrentUser: currentUser.reset,
}

export async function componentDidMount() {
  const {
    currentUser,
    token,
    setValidating,
    fetchCurrentUser,
    goToLogin,
  } = this.props

  if (currentUser.initialized) {
    if (!currentUser.payload.data.is_internal) {
      return goToLogin()
    }

    return
  }

  if (!token) {
    return goToLogin()
  }

  setValidating(true)

  try {
    const res = await fetchCurrentUser()
    if (res.value.data.is_internal) {
      setValidating(false)
    } else {
      goToLogin({ query: { error: 'Your session has been terminated' } })
    }
  } catch (err) {
    goToLogin()
  }
}

export function componentDidUpdate(prevProps) {
  const { currentUser, goToPDMSearch } = this.props
  if (prevProps.currentUser !== currentUser) {
    if (isPdmUser(currentUser)) {
      return goToPDMSearch()
    }
  }
}

export default compose(
  withState('validating', 'setValidating', false),
  connect(mapProps, actions),
  withHandlers({ goToLogin, goToPDMSearch }),
  lifecycle({ componentDidMount, componentDidUpdate }),
  nonOptimalStates([
    {
      when: (props) => props.validating,
      render: (props) => (
        <h1>
          <i className="fa fa-spin fa-refresh" /> Loading...
        </h1>
      ),
    },
  ])
)(Home)
