import React, { Component } from 'react'
import { array, any, bool, func } from 'prop-types'
import Select from 'react-select'

export default class SelectDropDown extends Component {
  static propTypes = {
    options: array,
    onChange: func.isRequired,
    onBlur: func,
    value: any,
    simpleValue: bool,
    multi: bool,
    showOneOptionAsLabel: bool,
    isLoading: bool,
    noResultsText: any,
    clearable: bool,
    disabled: bool,
    componentClass: func,
  }

  static defaultProps = {
    clearable: false,
    simpleValue: true,
    multi: false,
    showOneOptionAsLabel: false,
    isLoading: false,
    disabled: false,
    componentClass: Select,
  }

  setSelectedValue = props => {
    const { options, showOneOptionAsLabel, value, onChange } = props
    if (!showOneOptionAsLabel) {
      return
    }
    // If there is only one option, select it unless it is the already-selected option.
    if (options && options.length === 1) {
      const defaultValue = options[0].value
      if (value == defaultValue) { // eslint-disable-line eqeqeq
        return
      }

      onChange && onChange(defaultValue)
    }
  }

  componentDidMount () {
    this.setSelectedValue(this.props)
  }

  componentDidUpdate(_prevProps) {
    this.setSelectedValue(this.props)
  }

  handleChange = (value, items) => {
    const { onChange, multi, simpleValue } = this.props

    if (multi) {
      if (simpleValue) {
        value = (value === '') ? [] : value.split(',')
      } else {
        value = items
      }
    }

    onChange && onChange(value)
  }

  render () {
    const { options, value, multi, showOneOptionAsLabel, onBlur, isLoading, clearable, ...rest } = this.props
    const ComponentClass = this.props.componentClass
    const handleBlur = onBlur && !multi ? () => onBlur(value) : () => {}

    if (showOneOptionAsLabel && options && options.length === 1) {
      return (
        <div className='form-control-static'>
          <div style={{marginTop: 2}}>
            {options[0].label}
          </div>
        </div>
      )
    }

    if (isLoading) {
      return (
        <ComponentClass {...rest} disabled placeholder={<i className='fa fa-refresh fa-spin' />} />
      )
    }

    return (
      <ComponentClass
        options={options}
        multi={multi}
        clearable={clearable}
        backspaceRemoves={clearable}
        deleteRemoves={clearable}
        value={value || ''}
        {...rest}
        onBlur={handleBlur}
        onChange={this.handleChange}
      />
    )
  }
}
