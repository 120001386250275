const initialState = {
  received: false,
  message: '',
}

export const RELOAD_REQUIRED = 'RELOAD_REQUIRED'

export const requireReload = payload => ({ type: RELOAD_REQUIRED, payload })

export default function reloadRequired (state = initialState, action) {
  switch (action.type) {
    case 'RELOAD_REQUIRED':
      return { ...state,
        received: true,
        message: action.payload }
    default:
      return state
  }
}
