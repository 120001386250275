import asyncComponent from '../AsyncComponent'

export const AccountForm = asyncComponent(() =>
  import(/* webpackChunkName: "Profiles" */ './AccountForm')
)

export const Gravatar = asyncComponent(() =>
  import(/* webpackChunkName: "Profiles" */ './Gravatar')
)

export const ChangePasswordForm = asyncComponent(() =>
  import(/* webpackChunkName: "Profiles" */ './ChangePasswordForm')
)

export const ProfileNav = asyncComponent(() =>
  import(/* webpackChunkName: "Profiles" */ './ProfileNav')
)