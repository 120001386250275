import asyncComponent from '../AsyncComponent'

export const ScrapingEnvironmentPage = asyncComponent(() =>
  import(/* webpackChunkName: "ScrapingEnvironment" */ './ScrapingEnvironmentPage')
)

export const ScrapingProfileDetail = asyncComponent(() =>
  import(/* webpackChunkName: "ScrapingProfileDetail" */ './ScrapingProfileDetail')
)

export const ScrapingProfileDetailBreadcrumb = asyncComponent(() =>
  import(/* webpackChunkName: "ScrapingProfileDetailBreadcrumb" */ './ScrapingProfileDetailBreadcrumb')
)
