export const SUPPLIER_SELECTION_CHANGE = 'ops/manageOrganizations/SUPPLIER_SELECTION_CHANGE'
export const CLIENT_SELECTION_CHANGE = 'ops/manageOrganizations/CLIENT_SELECTION_CHANGE'

const initialState = {
  selectedSupplier: null,
  selectedClient: null,
}

export default function reducer (state = initialState, action) {
  switch (action.type) {
    case SUPPLIER_SELECTION_CHANGE:
      return {...state,
        selectedSupplier: action.data}
    case CLIENT_SELECTION_CHANGE:
      return {...state,
        selectedClient: action.data}
    default:
      return state
  }
}

export function changeSupplier (data) {
  return {
    type: SUPPLIER_SELECTION_CHANGE,
    data,
  }
}

export function changeClient (data) {
  return {
    type: CLIENT_SELECTION_CHANGE,
    data,
  }
}
