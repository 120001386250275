import React from 'react'
import { string } from 'prop-types'

const pattern = /@\[([^)]+)\]\(([A-Za-z0-9-]+)\)/

export default function ParsedComment (props) {
  const { comment, ...rest } = props
  if (!comment) {
    return null
  }

  const children = []
  let match
  let str = comment
  let limit = 0

  while ((match = str.match(pattern)) && limit < 10) {
    const [mention, name] = match
    const before = str.substring(0, match.index)
    str = str.substring(match.index + mention.length)

    children.push(before)
    children.push(<b key={limit}>@{name}</b>)
    limit++
  }

  children.push(str)

  return <p {...rest}>{children}</p>
}

ParsedComment.propTypes = {
  comment: string,
}
