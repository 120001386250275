import asyncComponent from '../../AsyncComponent'

export const P360Dashboard = asyncComponent(() =>
  import(/* webpackChunkName: "P360Dashboard" */ './P360Dashboard')
)

export const P360ReportDetail = asyncComponent(() =>
  import(/* webpackChunkName: "P360Dashboard" */ './P360ReportDetail')
)

export const P360ReportCreate = asyncComponent(() =>
  import(/* webpackChunkName: "P360Dashboard" */ './P360ReportCreate')
)
