import React from 'react'
import Datetime from 'react-datetime'
import { Button, InputGroup, FormControl } from 'react-bootstrap'
import {
  compose,
  defaultProps,
  mapProps,
  setDisplayName,
  renameProp,
  withHandlers,
} from 'recompose'
import { withFeedback } from './FieldFeedback'
import MaskedInput from 'react-text-mask'

const dateMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]

export const renderInput = (ownProps) => (inputProps, open) => {
  return (
    <>
      <InputGroup>
        <InputGroup.Button>
          <Button onClick={open} disabled={ownProps.disabled}>
            <i className="fa fa-calendar" />
          </Button>
        </InputGroup.Button>

        <FormControl
          componentClass={MaskedInput}
          mask={dateMask}
          placeholder="MM/DD/YYYY"
          {...inputProps}
        />
      </InputGroup>

      {ownProps.clearable && !ownProps.disabled && inputProps.value && (
        <FormControl.Feedback>
          <span>
            <Button
              bsStyle="link"
              onClick={ownProps.clear}
              className="btn-feedback"
            >
              &times;
            </Button>
          </span>
        </FormControl.Feedback>
      )}
    </>
  )
}

export const clear = (props) => () => props.onChange(null)

const DateTime = compose(
  defaultProps({
    dateFormat: 'MM/DD/YYYY',
    timeFormat: false,
    utc: true,
    showInput: true,
    closeOnSelect: true,
  }),
  renameProp('showInput', 'input'),
  withHandlers({ clear }),
  // These are separate because renderInput() calls clear()
  withHandlers({ renderInput }),
  setDisplayName('DateTime')
)((props) => <Datetime {...props} />)

Datetime.Field = DateTime.Field = compose(
  mapProps(({ input: { value, onChange }, ...rest }) => ({
    value,
    onChange,
    ...rest,
  })),
  withFeedback
)(DateTime)

export default DateTime
