import React from 'react'
import isArray from 'lodash/isArray'
import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'

export default class JsonAPI extends React.Component {
  state = { res: { hasStarted: false, isLoading: true } }

  componentDidMount() {
    // TODO: cancel the request if the component unmounts
    this.fetch()
  }

  componentDidUpdate({children: _, ...prevProps}) {
    const {children, ...props} = this.props
    if (!isEqual(prevProps, props)) this.fetch()
  }

  fetch = debounce(async () => {
    const { endpoint, params } = this.props
    this.setState({ res: { hasStarted: true, isLoading: true } })
    try {
      const res = await (isArray(params) ? endpoint(...params) : endpoint(params))
      this.setState({ res: { ...res, hasStarted: true, isLoading: false } })
    } catch (error) {
      this.setState({ res: { hasStarted: true, isLoading: false, error } })
    }
  }, 200)

  render() {
    const { children } = this.props
    return children({ ...this.state.res, refetch: this.fetch })
  }
}
