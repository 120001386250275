import React from 'react'
import { func } from 'prop-types'
import createReactClass from 'create-react-class'

export default createReactClass({
  displayName: 'RefreshButton',

  propTypes: {
    onClick: func.isRequired,
  },

  handleClick (e) {
    e.preventDefault()
    this.props.onClick()
  },

  render () {
    return (
      <button className='btn btn-primary btn-md' onClick={this.handleClick}>
        <i className='fa fa-refresh' /> Refresh
      </button>
    )
  },
})
