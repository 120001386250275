import _ from 'lodash'

const initialState = {
  tags: [],
  productServiceAreas: [],
  removed: [],
  total: 0,
  found: 0,
  missing: 0,
  duplicates: 0,
}

const SUFFIX = 'MAPPING_SERVICE_AREAS'
export const INIT = `INIT_${SUFFIX}`
export const ADD = `ADD_${SUFFIX}`
export const DROP = `DROP_${SUFFIX}`

export const init = payload => ({ type: INIT, payload })
export const add = payload => ({ type: ADD, payload: payload.trim() })
export const drop = payload => ({ type: DROP, payload })

export function initReducer (state, {payload}) {
  return recalculateReducer({ ...state, ...payload })
}

export function addReducer (state, {payload}) {
  if (!payload || !payload.trim()) {
    return state
  }

  const matchTag = tag => tag.value === payload

  if (_.some(state.tags, matchTag)) {
    return { ...state, duplicates: state.duplicates + 1 }
  }

  const removed = [...state.removed]
  const restored = _.remove(removed, matchTag)
  if (restored.length > 0) {
    return {
      ...state,
      removed,
      tags: state.tags.concat(restored),
    }
  }

  const psa = _.find(state.productServiceAreas, psa => psa.serviceArea === payload) || {}
  const status = psa.id ? 'found' : 'missing'
  const add = {
    id: psa.id || payload,
    value: payload,
    status,
    psa
  }

  return {
    ...state,
    total: state.total + 1,
    tags: [...state.tags, add],
    [status]: state[status] + 1,
  }
}

export function dropReducer (state, {payload}) {
  const drop = state.tags[payload]
  const tags = _.filter(state.tags, (tag, index) => index !== payload)
  const removed = (drop && drop.status === 'idle' && [...state.removed, drop]) || state.removed

  return recalculateReducer({
    ...state,
    tags,
    removed
  })
}

export function recalculateReducer (state) {
  const tags = state.tags

  return {
    ...state,
    total: tags.length,
    found: _.filter(tags, tag => tag.status === 'found').length,
    missing: _.filter(tags, tag => tag.status === 'missing').length,
  }
}

export default function mappingServiceAreas (state = initialState, action) {
  // Not to be reduced to `if (!action.payload)` because 0 is a valid payload.
  if (action.payload === null || action.payload === undefined) {
    return state
  }

  switch (action.type) {
    case INIT: return initReducer(state, action)
    case ADD: return addReducer(state, action)
    case DROP: return dropReducer(state, action)
    default: return state
  }
}
