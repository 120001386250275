import React from 'react'
import { object } from 'prop-types'
import { compose, lifecycle, withHandlers, withStateHandlers } from 'recompose'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { isPristine } from 'redux-form'
import ConfirmDialog from './ConfirmDialog'

export class ConfirmDirtyNavigation extends React.Component {
  state = { showNavigationConfirm: false, nextLocation: null }

  componentDidMount () {
    const { route, router } = this.props
    router.setRouteLeaveHook(route, this.routerWillLeave)
  }

  routerWillLeave = nextLocation => {
    if (this.props.pristine || this.state.showNavigationConfirm) {
      return
    }

    if (nextLocation && nextLocation.state && nextLocation.state.confirm === 'skip') {
      return
    }

    this.setState({ showNavigationConfirm: true, nextLocation })
    return false
  }

  handleConfirm = () => {
    const { nextLocation } = this.state
    this.handleClose()
    this.props.router.push(nextLocation)
  }

  handleClose = () => {
    this.setState({ showNavigationConfirm: false, nextLocation: null })
  }

  render () {
    const {
      component: C,
      ...rest
    } = this.props

    return (
      <React.Fragment>
        <ConfirmDialog
          title="Confirm Navigation"
          body="You have unsaved changes! Are you sure you want to leave?"
          show={this.state.showNavigationConfirm}
          onConfirm={this.handleConfirm}
          onClose={this.handleClose}
        />
        <C {...rest} />
      </React.Fragment>
    )
  }
}

ConfirmDirtyNavigation.propTypes = {
  route: object.isRequired,
}

const initialState = { show: false, nextLocation: null }
export const setNextLocation = () => nextLocation => ({ show: !!nextLocation, nextLocation })

export const onConfirm = props => () => {
  const { setNextLocation, nextLocation, router, action='push' } = props
  setNextLocation()
  router[action](nextLocation)
}

export const onClose = props => () => props.setNextLocation()

const spec = {
  componentDidMount () {
    const { router, route } = this.props
    router.setRouteLeaveHook(route, nextLocation => {
      const { show, pristine, setNextLocation } = this.props
      if (pristine || show) {
        return
      }

      if (nextLocation && nextLocation.state && nextLocation.state.confirm === 'skip') {
        return
      }

      setNextLocation(nextLocation)
      return false
    })
  }
}

export const mapProps = (state, props) => ({
  pristine: isPristine(props.form)(state),
  title: "Confirm Navigation",
  body: "You have unsaved changes! Are you sure you want to leave?"
})

/* Alternative implementation that you can simply render as a component
 * <DirtyConfirmDialog form="formName" route={this.props.route} />
 * because when you use the HOC withConfirmDirtyNavigation, the confirm
 * dialog will receive any other HOCs that get added after, which may
 * make debugging harder.
 */
export const DirtyConfirmDialog = compose(
  withRouter,
  connect(mapProps),
  withStateHandlers(initialState, { setNextLocation }),
  withHandlers({ onConfirm, onClose }),
  lifecycle(spec)
)(ConfirmDialog)

export default function withConfirmDirtyNavigation (options = {}) {
  const { component, form } = options
  const pristine = isPristine(form)

  function mapStateToProps (state) {
    return { component, pristine: pristine(state) }
  }

  const result = connect(mapStateToProps)(ConfirmDirtyNavigation)
  const componentName = component.displayName || component.name || 'Anonymous'
  result.displayName = `ConfirmDirtyNavigation(${componentName})`
  return result
}
