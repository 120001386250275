import React from 'react'
import PropTypes from 'prop-types'
import { OverlayTrigger, Tooltip as RBTooltip } from 'react-bootstrap'
import classNames from 'classnames'

// OverlayTrigger doesn't allow the tooltip to be disabled, even for empty string;
// but rendering OverlayTrigger conditionally causes the input to lose focus when
// the title changes because the existing component unmounts. As a workaround,
// swap out the tooltip for a replacement that renders nothing.
export class NullTooltip extends RBTooltip {
  render() { return null }
}

export default function Tooltip (props) {
  const { id, title, iconType, className, placement, style, tooltipSize, children, hide, contentsFirst, ...rest } = props

  const tooltipClasses = classNames({
    wide : tooltipSize === 'wide',
    hide : hide,
  })

  const tooltip = (
    title ? <RBTooltip id={id} className={tooltipClasses} >{title}</RBTooltip> : <NullTooltip id={id} />
  )

  let contents
  if (iconType) {
    const icon = <i key="icon" className={`fa fa-${iconType}`} />
    if (contentsFirst) {
      contents = [children, ' ', icon]
    } else {
      contents = [icon, ' ', children]
    }
  } else {
    contents = [children]
  }

  return (
    <OverlayTrigger placement={placement || 'bottom'} overlay={tooltip} {...rest}>
      <span className={className} style={style}>
        {contents}
      </span>
    </OverlayTrigger>
  )
}

Tooltip.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
}

Tooltip.defaultProps = {
  hide: false
}
