import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { Modal } from 'react-bootstrap'
import filepicker from 'filepicker-js'
import { client } from '../../lib/opsApi'
import ErrorPanel from '../ErrorPanel'
import Loader from './Loader'
import ResourceError from './ResourceError'
import fetcher from './fetcher'
import withCurrentUser from './withCurrentUser'

// https://www.filestack.com/docs/javascript-api/store/fs-v2
const errors = {
  111: "Your browser doesn't support reading from DOM File objects.",
  112: "Your browser doesn't support reading from different domains.",
  113: 'The website of the URL you provided does not allow other domains to read data.',
  114: 'The website of the URL you provided had an error.',
  115: 'File not found.',
  118: 'Unknown read error.',
  121: 'The Blob to write to could not be found.',
  122: 'The Remote URL could not be reached.',
  123: 'Unknown write error.',
  151: "The file store couldn't be reached.",
  400: "Bad parameters were passed to the server. This often will be the case when you turned on security but haven't passed up a policy or signature.",
  403: "Invalid request - The policy and/or signature don't allow you to make this request."
}

export function parseError({ code }) {
  return errors[code] || `Unrecognized error: ${code}`
}

const inputStyle = {
  width: '100%',
  marginTop: 6
}

export class FilepickerUploadForm extends Component {
  static propTypes = {
    onAddEdit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    currentUser: PropTypes.object.isRequired,
    results: PropTypes.array,
    loading: PropTypes.bool,
    fetchError: PropTypes.object,
    refresh: PropTypes.func
  }

  static defaultProps = {
    results: [],
    loading: false,
    fetchError: undefined,
    refresh: undefined
  }

  state = {
    submitting: false,
    error: null,
    progress: 0,
    enableSubmit: false
  }

  handleCancel = () => {
    this.props.onClose()
  }

  handleSave = e => {
    e.preventDefault()
    if (process.env.NODE_ENV === 'test') {
      return
    }

    const input = this.documentName
    const [
      {
        data: { policy, signature }
      }
    ] = this.props.results
    const options = {
      location: 'S3',
      path: `${this.props.currentUser.organization_id}/`,
      policy,
      signature
    }

    this.setState({ submitting: true })

    filepicker.store(
      input,
      options,
      this.handleUploadDocumentSuccess,
      this.handleUploadDocumentError,
      this.handleUploadDocumentProgress
    )
  }

  handleUploadDocumentSuccess = res => {
    this.setState({ submitting: false })
    this.props.onAddEdit(res)
  }

  handleUploadDocumentError = error => {
    this.setState({
      submitting: false,
      error: parseError(error)
    })
  }

  handleUploadDocumentProgress = progress => {
    this.setState({ progress })
  }

  handleChangeDocument = e => {
    e.preventDefault()
    this.setState({ enableSubmit: true })
  }

  refDocumentName = ref => {
    this.documentName = ref
  }

  renderBody = () => {
    const { loading, fetchError, refresh } = this.props

    if (loading) {
      return <Loader />
    }

    if (fetchError) {
      return <ErrorPanel error={fetchError} onClose={refresh} />
    }

    const { submitting, error } = this.state

    return (
      <React.Fragment>
        <div className="form-group">
          <label className="control-label col-xs-3">Attach Document</label>
          <div className="col-xs-9">
            <input
              ref={this.refDocumentName}
              type="file"
              accept="*/*"
              style={inputStyle}
              onChange={this.handleChangeDocument}
              disabled={submitting}
            />
          </div>
        </div>

        {error && (
          <div className="form-group">
            <div className="col-xs-9 col-xs-offset-3">
              <ResourceError error={error} />
            </div>
          </div>
        )}
      </React.Fragment>
    )
  }

  render() {
    const { progress, submitting, enableSubmit } = this.state

    return (
      <Modal id="filepicker-upload" show onHide={this.handleCancel}>
        <form className="form-horizontal" onSubmit={this.handleSave}>
          <Modal.Header closeButton>
            <Modal.Title>Upload New Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.renderBody()}</Modal.Body>
          <Modal.Footer>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={submitting || !enableSubmit}
            >
              {submitting && <i className="fa fa-cog fa-spin" />} Upload{' '}
              {submitting && `${progress}%`}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default compose(
  withCurrentUser,
  fetcher({ fetch: () => client.get('attachment_security_policies') })
)(FilepickerUploadForm)
