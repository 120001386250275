import React from 'react'
import PropTypes from 'prop-types'
import * as BS from 'react-bootstrap'
import capitalize from 'lodash/capitalize'
const checkStyle = { marginLeft: 10 }

export class CheckboxOption extends React.Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    input: PropTypes.object.isRequired,
    option: PropTypes.object.isRequired,
    disabled: PropTypes.bool
  }

  static defaultProps = {
    disabled: false
  }

  handleChange = e => {
    const {
      input: { value: inputValue, onBlur, onChange },
      option: { value }
    } = this.props

    const arr = [...inputValue]
    if (e.target.checked) {
      arr.push(value)
    } else {
      arr.splice(arr.indexOf(value), 1)
    }

    onBlur(arr)
    return onChange(arr)
  }

  render() {
    const {
      input: { name, value: inputValue, onFocus },
      option: { label, value, disabled },
      index,
      disabled: fieldDisabled
    } = this.props

    return (
      <BS.Checkbox
        key={index}
        name={`${name}[${index}]`}
        value={value}
        checked={inputValue.includes(value)}
        onChange={this.handleChange}
        onFocus={onFocus}
        disabled={disabled || fieldDisabled}
        style={checkStyle}
        inline
      >
        {label}
      </BS.Checkbox>
    )
  }
}

export default function CheckboxGroup(props) {
  const { name, onChange, value, options = [] } = props

  return options.map((option, index) => (
    <CheckboxOption
      key={index}
      input={{ name, value, onChange }}
      option={option}
    />
  ))
}

CheckboxGroup.Field = props => {
  const { input, meta, hideValidation, disabled, options } = props
  const boxes = options.map((option, index) => (
    <CheckboxOption
      key={index}
      input={input}
      option={option}
      index={index}
      disabled={disabled}
    />
  ))

  return (
    <>
      {boxes}

      {!hideValidation && meta.touched && (
        <div>
          {meta.error && (
            <div className="text-danger">{capitalize(meta.error)}</div>
          )}
          {meta.warning && (
            <div className="text-warning">{capitalize(meta.warning)}</div>
          )}
        </div>
      )}
    </>
  )
}

CheckboxGroup.Field.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  hideValidation: PropTypes.bool,
  disabled: PropTypes.bool,
  options: PropTypes.array
}

CheckboxGroup.Field.defaultProps = {
  hideValidation: false,
  disabled: false,
  options: []
}
