import React from 'react'
import PropTypes from 'prop-types'
import ReactTable from 'react-table'
//import 'react-table/react-table.css' // CSS selectors are too generic
import _ from 'lodash'
import { withHandlers, withProps } from 'recompose'
import { Button, FormControl } from 'react-bootstrap'
import { HighlightCell } from '../Cells'
import './index.css'

const PageButton = withProps({ block: true })(Button)

const getTheadThProps = () => ({ style: { outline: 0 } })

const Grid = React.forwardRef((props, ref) => {
  const { componentClass: Component, ...rest } = props

  return (
    <Component
      ref={ref}
      filterable
      defaultPageSize={10}
      className="-striped -highlight"
      getTheadThProps={getTheadThProps}
      PreviousComponent={PageButton}
      NextComponent={PageButton}
      {...rest}
    />
  )
})

Grid.propTypes = {
  componentClass: PropTypes.any.isRequired, // should be PropTypes.elementType
}

Grid.defaultProps = {
  componentClass: ReactTable,
}

export default Grid

export const onChange = (props) => (e) => props.onChange(e.target.value)

export const FormControlFilter = withHandlers({
  onChange,
})(({ filter, ...rest }) => (
  <FormControl value={(filter && filter.value) || ''} {...rest} />
))
Grid.Filter = (props) => <FormControlFilter {...props} /> // https://github.com/react-tools/react-table/pull/1050

export const FormControlDropdownFilter = withHandlers({ onChange })(
  ({ filter, column: { options = [] }, ...rest }) => (
    <FormControl
      componentClass="select"
      value={filter && filter.value}
      {...rest}
    >
      {options.map((option, index) => (
        <option key={index} value={option.value}>
          {option.label}
        </option>
      ))}
    </FormControl>
  )
)

Grid.DropdownFilter = (props) => <FormControlDropdownFilter {...props} />

function containsInsensitive(filter, row) {
  let value;
  if(_.isBoolean(filter.value)) {
    value = filter.value
  } else {
    value = filter && filter.value && filter.value.toLowerCase()
  }
  const columnFilter = row[filter.id]

  if (!columnFilter && value) return false
  return (
    columnFilter == null ||
    value == null ||
    String(columnFilter).toLowerCase().includes(value)
  )
}

function getFiltered(gridState) {
  return { filtered: gridState.filtered }
}

Grid.filterColumnProps = Object.freeze({
  filterMethod: containsInsensitive,
  getProps: getFiltered,
  Cell: HighlightCell,
  Filter: Grid.Filter,
})

Grid.dropdownFilterColumnProps = Object.freeze({
  getProps: getFiltered,
  Filter: Grid.DropdownFilter,
})
