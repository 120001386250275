import asyncComponent from '../../AsyncComponent'

export const NetworkMapping = asyncComponent(() =>
  import(/* webpackChunkName: "NetworkMappings" */ './NetworkMapping')
)

export const NetworkMappings = asyncComponent(() =>
  import(/* webpackChunkName: "NetworkMappings" */ './NetworkMappings')
)

export const NetworkMappingProperties = asyncComponent(() =>
  import(/* webpackChunkName: "NetworkMappings" */ './NetworkMappingProperties')
)

export const NetworkMappingNetworks = asyncComponent(() =>
  import(/* webpackChunkName: "NetworkMappings" */ './NetworkMappingNetworks')
)

export const NetworkMappingActiveProjects = asyncComponent(() =>
  import(/* webpackChunkName: "NetworkMappings" */ './NetworkMappingActiveProjects')
)
