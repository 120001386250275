import React from 'react'
import PT from 'prop-types'
import Snackbar from './Snackbar'

ErrorAlert.propTypes = {
  error: PT.object,
}

ErrorAlert.defaultProps = {
  error: null,
}

export default function ErrorAlert({ error }) {
  const [showing, setShowing] = React.useState(error != null)

  React.useEffect(() => {
    setShowing(error != null)
  }, [error])

  return (
    <Snackbar show={showing} type="danger" onClose={() => setShowing(false)}>
      {getMessage(error)}
    </Snackbar>
  )
}

function getMessage(error) {
  if (!error) return null

  let msg = error.message
  msg = msg.replace(/^\s*GraphQL error:\s*/im, '')
  msg = msg.replace(/^\s*A business error occurred:\s*/im, '')
  return msg
}
