import React from 'react'
import PropTypes from 'prop-types'
import { MatchHighlight } from '../withHighlight'

HighlightCell.propTypes = {
  column: PropTypes.object,
  columnProps: PropTypes.object,
  value: PropTypes.string,
}

HighlightCell.defaultProps = {
  column: undefined,
  columnProps: undefined,
  value: undefined,
}

export default function HighlightCell(props) {
  const { column, columnProps, value } = props

  if (value == null) return null
  const item = columnProps.rest.filtered.find((x) => x.id === column.id)

  return (
    <div className="whitespace-pre-line">
      <MatchHighlight match={item && item.value} children={value} />
    </div>
  )
}
