import { useEffect } from 'react'
import withCustomBreadcrumb from './withCustomBreadcrumb'

export function Breadcrumb({ setBreadcrumb, children }) {
  useEffect(
    () => {
      setBreadcrumb(children)
    },
    [setBreadcrumb, children]
  )

  return null
}

const result = withCustomBreadcrumb(Breadcrumb)
result.BaseComponent = Breadcrumb
export default result
