import asyncComponent from '../AsyncComponent'

export const SourceBreadcrumb = asyncComponent(() =>
  import(/* webpackChunkName: "Sources" */ './SourceBreadcrumb')
)

export const SourceDetail = asyncComponent(() =>
  import(/* webpackChunkName: "Sources" */ './SourceDetail')
)

export const SourceList = asyncComponent(() =>
  import(/* webpackChunkName: "Sources" */ './SourceList')
)

export const SourceUsers = asyncComponent(() =>
  import(/* webpackChunkName: "Sources" */ './SourceUsers')
)

export const SourceForm = asyncComponent(() =>
  import(/* webpackChunkName: "Sources" */ './SourceForm')
)
