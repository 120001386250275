import asyncComponent from '../AsyncComponent'

export const CustomAttributesConfigurationsPage = asyncComponent(() =>
  import(
    /* webpackChunkName: "Network360" */ './CustomAttributesConfigurationsPage'
  )
)

export const CustomAttributeConfigurationPage = asyncComponent(() =>
  import(
    /* webpackChunkName: "Network360" */ './CustomAttributeConfigurationPage'
  )
)

export const CreateNewCustomAttributeConfigurationPage = asyncComponent(() =>
  import(
    /* webpackChunkName: "Network360" */ './CreateNewCustomAttributeConfigurationPage'
  )
)
