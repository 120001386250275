import { MenuItem } from 'react-bootstrap'
import { withProps } from 'recompose'
import { Link } from 'react-router'

/* For embedding inside menus
 * <DropdownButton>
 *   <MenuItem header>Actions</MenuItem>
 *   <LinkMenuItem to="/edit">Edit</LinkMenuItem>
 * </DropdownButton>
*/
export default withProps({ componentClass: Link, href: '/' })(MenuItem)
