import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, HelpBlock } from 'react-bootstrap';
import capitalize from 'lodash/capitalize';

export default function FieldValidator(props) {
  const {
    meta: { touched, error, warning } = {}
  } = props;

  function validationState() {
    if (touched && error) return 'error';
    if (touched && warning) return 'warning';
    return null;
  }

  return (
    <FormGroup validationState={validationState()} bsClass="">
      <HelpBlock>{touched && capitalize(error || warning)}</HelpBlock>
    </FormGroup>
  );
}

FieldValidator.propTypes = {
  meta: PropTypes.object.isRequired
};
