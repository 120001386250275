import asyncComponent from '../AsyncComponent'

export const FtpConfiguration = asyncComponent(() =>
  import(/* webpackChunkName: "Organizations" */ './FtpConfiguration')
)

export const FtpConfigurationBreadcrumb = asyncComponent(() =>
  import(/* webpackChunkName: "Organizations" */ './FtpConfigurationBreadcrumb')
)

export const OrganizationContacts = asyncComponent(() =>
  import(/* webpackChunkName: "Organizations" */ './OrganizationContacts')
)

export const OrganizationDetail = asyncComponent(() =>
  import(/* webpackChunkName: "Organizations" */ './OrganizationDetail')
)

export const OrganizationDeliveryConfigurations = asyncComponent(() =>
  import(
    /* webpackChunkName: "Organizations" */ './OrganizationDeliveryConfigurations'
  )
)

export const OrganizationEmailConfigurations = asyncComponent(() =>
  import(
    /* webpackChunkName: "Organizations" */ './OrganizationEmailConfigurations'
  )
)

//export { default as OrganizationEmployer } from './OrganizationEmployer'
export const OrganizationEmployer = asyncComponent(() =>
  import(/* webpackChunkName: "Organizations" */ './OrganizationEmployer')
)

//export { default as OrganizationEmployerBreadcrumb } from './OrganizationEmployerBreadcrumb'
export const OrganizationEmployerBreadcrumb = asyncComponent(() =>
  import(
    /* webpackChunkName: "Organizations" */ './OrganizationEmployerBreadcrumb'
  )
)

//export { default as OrganizationEmployers } from './OrganizationEmployers'
export const OrganizationEmployers = asyncComponent(() =>
  import(/* webpackChunkName: "Organizations" */ './OrganizationEmployers')
)

export const OrganizationForm = asyncComponent(() =>
  import(/* webpackChunkName: "Organizations" */ './OrganizationForm')
)

export const OrganizationFtpConfigurations = asyncComponent(() =>
  import(
    /* webpackChunkName: "Organizations" */ './OrganizationFtpConfigurations'
  )
)

export const OrganizationList = asyncComponent(() =>
  import(/* webpackChunkName: "Organizations" */ './OrganizationList')
)

export const OrganizationUsers = asyncComponent(() =>
  import(/* webpackChunkName: "Organizations" */ './OrganizationUsers')
)

export const OrganizationSources = asyncComponent(() =>
  import(/* webpackChunkName: "Organizations" */ './OrganizationSources')
)

export const OrganizationFeatures = asyncComponent(() =>
  import(/* webpackChunkName: "Organizations" */ './OrganizationFeatures')
)

export const OrganizationSourcesForm = asyncComponent(() =>
  import(/* webpackChunkName: "Organizations" */ './OrganizationSourcesForm')
)

export const OrganizationAddForm = asyncComponent(() =>
  import(/* webpackChunkName: "Organizations" */ './OrganizationAddForm')
)

export const OrganizationWebsiteConfigurations = asyncComponent(() =>
  import(
    /* webpackChunkName: "Organizations" */ './OrganizationWebsiteConfigurations'
  )
)
