import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import Select from 'react-select'
import SelectV1 from 'react-select-v1'
import Virtual from 'react-virtualized-select'
import { compose, branch, defaultProps, mapProps, withProps } from 'recompose'
import FieldFeedback, { withFeedback } from './FieldFeedback'
import 'react-select-v1/dist/react-select.css'

// https://github.com/JedWatson/react-select/pull/2539#issuecomment-388299291
class FixedSelect extends SelectV1 {
  handleKeyDown(event) {
    //todo remove this class when https://github.com/JedWatson/react-select/pull/2539 will be merged
    if (this.props.disabled) return
    if (typeof this.props.onInputKeyDown === 'function') {
      this.props.onInputKeyDown(event)
      if (event.defaultPrevented) {
        return
      }
    }
    if (event.keyCode === 13) {
      if (!this.state.isOpen) {
        return
      }
      event.preventDefault()
      event.stopPropagation()
      this.selectFocusedOption()
    } else {
      super.handleKeyDown(event)
    }
  }
}

class FixedPortalSelect extends FixedSelect {
  renderOuter(options, valueArray, focusedOption) {
    const dimensions = this.wrapper
      ? this.wrapper.getBoundingClientRect()
      : null
    const menu = super.renderMenu(options, valueArray, focusedOption)

    if (!menu || !dimensions) return null

    const maxHeight =
      document.body.offsetHeight - (dimensions.top + dimensions.height)
    return ReactDOM.createPortal(
      <React.Fragment>
        <div className="Select-mask" />
        <div
          ref={ref => {
            this.menuContainer = ref
          }}
          className="Select-menu-outer"
          onClick={e => {
            e.stopPropagation()
          }}
          style={{
            ...this.props.menuContainerStyle,
            zIndex: 9999,
            position: 'absolute',
            width: dimensions.width,
            top: window.scrollY + dimensions.top + dimensions.height,
            left: window.scrollX + dimensions.left,
            maxHeight: Math.min(maxHeight, 200),
            overflow: 'hidden'
          }}
        >
          <div
            ref={ref => {
              this.menu = ref
            }}
            role="listbox"
            tabIndex={-1}
            className="Select-menu"
            id={`${this._instancePrefix}-list`}
            style={{
              ...this.props.menuStyle,
              maxHeight: Math.min(maxHeight, 200)
            }}
            onScroll={this.handleMenuScroll}
            onMouseDown={this.handleMouseDownOnMenu}
          >
            {menu}
          </div>
        </div>
      </React.Fragment>,
      document.body
    )
  }
}

export const portalGenerator = Component => {
  return class Select extends Component {
    static propTypes = {
      menuContainerStyle: PropTypes.object,
      menuStyle: PropTypes.object
    }

    static defaultProps = {
      menuContainerStyle: undefined,
      menuStyle: undefined,
      ...Component.defaultProps
    }

    // Until react-select converts to the React createPortal API
    // Adapted from https://github.com/JedWatson/react-select/issues/810
    refMenuContainer = ref => {
      this.menuContainer = ref
    }
    refMenu = ref => {
      this.menu = ref
    }

    handleOuterClick = e => e.stopPropagation()

    renderOuter(options, valueArray, focusedOption) {
      const dimensions = this.wrapper
        ? this.wrapper.getBoundingClientRect()
        : null

      const menu = super.renderMenu(options, valueArray, focusedOption)

      if (!menu || !dimensions) return null

      const maxHeight =
        document.body.offsetHeight - (dimensions.top + dimensions.height)
      return ReactDOM.createPortal(
        <div
          ref={this.refMenuContainer}
          role="presentation"
          className="Select-menu-outer"
          onClick={this.handleOuterClick}
          style={{
            ...this.props.menuContainerStyle,
            zIndex: 9999,
            position: 'absolute',
            width: dimensions.width,
            top: dimensions.top + dimensions.height,
            left: dimensions.left,
            maxHeight: Math.min(maxHeight, 200),
            overflow: 'hidden'
          }}
        >
          <div
            ref={this.refMenu}
            role="listbox"
            tabIndex={-1}
            className="Select-menu"
            id={`${this._instancePrefix}-list`}
            style={{
              ...this.props.menuStyle,
              maxHeight: Math.min(maxHeight, 200)
            }}
            onScroll={this.handleMenuScroll}
            onMouseDown={this.handleMouseDownOnMenu}
          >
            {menu}
          </div>
        </div>,
        document.body
      )
    }
  }
}

const handleError = Component => props => {
  // eslint-disable-next-line
  const { error, meta, isLoading, onRefresh, label } = props

  if (!isLoading && error) {
    return (
      <FieldFeedback
        meta={{
          ...meta,
          error,
          loading: isLoading,
          touched: true
        }}
        onRefresh={onRefresh}
      >
        <Component disabled isLoading={isLoading} placeholder={label} />
      </FieldFeedback>
    )
  }

  return <Component {...props} />
}

const asField = compose(
  defaultProps({ simpleValue: true }),
  mapProps(({ input: { value, onChange }, ...rest }) => ({
    value,
    onChange,
    ...rest
  })),
  branch(props => props.error, handleError, withFeedback)
)

Select.Field = asField(Select)
Select.Async.Field = asField(Select.Async)
Select.AsyncCreatable.Field = asField(Select.AsyncCreatable)
Select.Creatable.Field = asField(Select.Creatable)

Select.Portal = FixedPortalSelect
Select.PortalAsync = portalGenerator(Select.Async)
Select.PortalAsyncCreatable = portalGenerator(Select.AsyncCreatable)
Select.PortalCreatable = portalGenerator(Select.Creatable)

Select.PortalVirtual = withProps({ selectComponent: Select.Portal })(Virtual)

Select.Portal.Field = asField(Select.Portal)
Select.PortalAsync.Field = asField(Select.PortalAsync)
Select.PortalAsyncCreatable.Field = asField(Select.PortalAsyncCreatable)
Select.PortalCreatable.Field = asField(Select.PortalCreatable)
Select.PortalVirtual.Field = asField(Select.PortalVirtual)

Select.Virtual = withProps({ selectComponent: Select })(Virtual)
Select.VirtualAsync = withProps({ selectComponent: Select.Async })(Virtual)
Select.VirtualAsyncCreatable = withProps({
  selectComponent: Select.AsyncCreatable
})(Virtual)
Select.VirtualCreatable = withProps({ selectComponent: Select.Creatable })(
  Virtual
)

Select.Virtual.Field = asField(Select.Virtual)
Select.VirtualAsync.Field = asField(Select.VirtualAsync)
Select.VirtualAsyncCreatable.Field = asField(Select.VirtualAsyncCreatable)
Select.VirtualCreatable.Field = asField(Select.VirtualCreatable)

export { FixedSelect as SelectV1 }
export default Select
