import { compose, lifecycle } from 'recompose'
import { connect } from 'react-redux'
import { setBreadcrumb } from '../../redux/reducers/breadcrumbPatch'

export default compose(
  connect(null, { setBreadcrumb }),
  lifecycle({
    componentWillUnmount() { this.props.setBreadcrumb() }
  })
)
