import React from 'react'
import { func, string, array, oneOfType } from 'prop-types'
import { MentionsInput, Mention } from 'react-mentions'
import CommentInputStyle from './CommentInputStyle'

export function displayTransform (id, display) {
  return '@' + display.substring(0, 99)
}

export default function CommentInput (props) {
  const {value, onChange, data, ...rest} = props

  return (
    <MentionsInput
      value={value || ''}
      onChange={onChange}
      displayTransform={displayTransform}
      className='react-mentions'
      style={CommentInputStyle}
      allowSpaceInQuery
      autoComplete='off'
      {...rest}
    >
      <Mention trigger='@' data={data} />
    </MentionsInput>
  )
}

CommentInput.propTypes = {
  value: string,
  onChange: func,
  data: oneOfType([array, func]),
}
